import {Flex, Typography} from 'antd';
import {isPersonAdmin} from 'utils';
import {useCurrentUser} from 'hooks/useCurrentUser';
import CreateZoneButton from 'components/buttons/CreateZoneButton';

const NoData = () => {
    const currentUser = useCurrentUser();

    return (
        <Flex style={{height: '100%'}} vertical justify={'center'}>
            <Flex style={{margin: '1em'}} justify={'center'}>
                <Typography.Paragraph style={{fontWeight:'bold', margin:0}}>No Data Found!</Typography.Paragraph>
            </Flex>
            <Flex style={{margin: '1em'}} justify={'center'}>
                {isPersonAdmin(currentUser) ?
                    <CreateZoneButton/> :
                    <Typography.Text>Please have an admin create a zone.</Typography.Text>
                }
            </Flex>
        </Flex>
    );
}

export default NoData;
