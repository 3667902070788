import {App, Button, Card, Flex, Select, Typography} from 'antd';
import {usePeople} from 'hooks/usePeople';
import {useOrg} from 'hooks/useOrg';
import {asOptions, getErrorMessage} from 'utils';
import apiClient from 'api';

import {LoginFormResponse} from 'pages/auth/login/LoginForm';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {current} from 'loader';

const AdminPage = () => {
    const {message} = App.useApp();
    const navigate = useNavigate();
    const [person, setPerson] = useState<string>();
    const [busy, setBusy] = useState(false);

    const people = usePeople();
    const org = useOrg();
    if (!org) return null;

    async function impersonate() {
        if (person) {
            setBusy(true);
            try {
                const data = await apiClient.post<LoginFormResponse>(
                    '/auth/impersonate', {
                        person_id: person,
                        org_id: org!.id
                    }
                );
                apiClient.setToken(data.token.access_token);
                await current();
                navigate('/');
            } catch (e) {
                message.error(getErrorMessage(e));
            } finally {
                setBusy(false);
            }
        }
    }

    return (
        <div>
            <p>This page is for system admins only. It will mostly be for creating/managing organizations.</p>
            <p>{process.env['REACT_APP_API_URL']}</p>
            <Flex>
                <Card style={{width: '100%', maxWidth: '400px'}}>
                    <Typography.Paragraph>
                        <Select
                            onChange={setPerson}
                            style={{width: '100%', maxWidth: '400px'}}
                            options={asOptions(people)}
                        />
                        <small>Select a person to login as them.</small>
                    </Typography.Paragraph>
                    <Button loading={busy} disabled={!person||busy} onClick={impersonate}>Impersonate</Button>
                </Card>
            </Flex>
        </div>
    );
}

export default AdminPage;
