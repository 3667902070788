import {OneToOneField, OneToManyField, ObjectType} from 'types';
import {Form, Select} from 'antd';

const RelationshipField = (props: {
    field: OneToOneField | OneToManyField,
    setField: (field: OneToOneField | OneToManyField) => void,
}) => {
    function onChange(allowed: string[]) {
        props.setField({...props.field, allowed: allowed as ObjectType[]});
    }

    return (
        <div className="formControl">
            <Form.Item
                label={'Object types that may be referenced'}
                rules={[{required: true, message: 'Select one or more values.'}]}
                initialValue={props.field.allowed}
                name={props.field.name}
            >
                <Select
                    style={{width: '100%'}}
                    mode="multiple"
                    onChange={onChange}
                    options={[
                        //{label: 'Agreement', value: 'agreement'},
                        //{label: 'Goal', value: 'goal'},
                        {label: 'Person', value: 'person'},
                        {label: 'Role', value: 'role'},
                        {label: 'Team', value: 'team'},
                        {label: 'Zone', value: 'zone'}
                    ]}
                />
            </Form.Item>
        </div>
    );
}

export default RelationshipField;
