import {Form, Input} from 'antd';

const AccountabilitiesStep = (props: {
    accountabilities: string,
    setAccountabilities: (accountabilities: string) => void,
}) => {
    const [form] = Form.useForm<{ accountabilities: string|undefined }>();
    Form.useWatch(values => {
        if (values.accountabilities !== undefined) {
            props.setAccountabilities(values.accountabilities);
        }
    }, form);

    return (
        <Form
            name="accountabilitiesStep"
            form={form}
            style={{maxWidth: 600}}
            initialValues={{accountabilities: props.accountabilities}}
            layout="vertical"
            autoComplete="off"
        >
            <Form.Item<{accountabilities: string}>
                label="What are the accountabilities for this role?"
                name="accountabilities"

                rules={[{required: true, message: "Please describe the accountabilities in detail."}]}
            >
                <Input.TextArea
                    rows={8}
                    placeholder={'Enter the accountabilities.  Markdown is accepted.'}
                />
            </Form.Item>
        </Form>)
}

export default AccountabilitiesStep;
