import type {Decision} from 'types';

import {useDecision} from 'hooks/useDecisions';
import DecisionDrawerDisplay from './DecisionDrawerDisplay';
import DecisionForm from 'components/forms/DecisionForm';


const DecisionDrawerContents = (props: {
    decision: Decision | undefined,
    edit?: boolean,
    setEdit?: (value: boolean) => void
}) => {
    /* This redundancy forces a re-render on decision data update. */
    const decision = useDecision(props.decision?.id);
    const onFinish = () => props.setEdit?.(false);

    if (!decision) return null;

    if (props.edit) {
        return (<DecisionForm decision={decision} onSave={onFinish} onCancel={onFinish} />);
    }

    return <DecisionDrawerDisplay decision={decision} />;
}

export default DecisionDrawerContents;
