import React from 'react';
import {Link} from 'react-router-dom';

import {Avatar, Flex, Table, Tag} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import {UserOutlined} from '@ant-design/icons';

import {asDataSource, statusColor, userTypeDisplay} from 'utils';
import {usePeople} from 'hooks/usePeople';

const columns: ColumnsType = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, {id, picture_small}) => {
            return (
                <Flex align={'center'} gap={'1rem'}>
                    {picture_small ?
                        <Avatar size={32} src={picture_small} /> :
                        <Avatar size={32} icon={<UserOutlined/>} />
                    }
                    <Link to={`/people/${id}`}>{text}</Link>
                </Flex>
            )
        }
    },
    {
        title: 'Type',
        dataIndex: 'userType',
        key: 'userType',
        render: text => userTypeDisplay(text)
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: text => (
            <Tag color={statusColor(text)} key={'status'}>
                {text ? text.toUpperCase() : 'ACTIVE'}
            </Tag>
        )
    }
];

const PeopleListPage = () => {
    const people = asDataSource(usePeople());
    return (
        <Table
            columns={columns}
            dataSource={people}
            pagination={false}
        />
    );
}

export default PeopleListPage;
