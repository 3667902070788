import React, {useState} from 'react';
import {App, Button, Form, FormProps, Input} from 'antd';
import {type ErrorDetail, type ErrorDetailDispatch, type Goal} from 'types';
import {AppDispatch, useAppDispatch} from 'store';
import {createGoal, updateGoal} from 'features/goalSlice';
import {useTemplate} from 'hooks/useTemplates';
import FieldFormItem from './common/FieldFormItem';

const GoalForm = (props: {
    goal?: Goal,
    onSave?: (goal: Goal) => void,
    onCancel?: () => void;
}) => {
    const [goal, setGoal] = useState<Goal & {[p: string]: any}>(props.goal ? props.goal : {} as Goal);
    const template = useTemplate(props.goal?.template.id, props.goal?.type);
    const templateFields = template ? template.fields : [];

    const dispatch: AppDispatch = useAppDispatch();
    const {message} = App.useApp();

    async function onCreate() {
        const res = await dispatch(createGoal(goal));
        if ((res as ErrorDetailDispatch<Goal>).error) {
            return message.error((res.payload as ErrorDetail).detail.toString());
        }
        props.onSave?.(goal);
        return message.success('Goal created successfully.');
    }

    async function onUpdate() {
        const res = await dispatch(updateGoal(goal));
        if ((res as ErrorDetailDispatch<Goal>).error) {
            return message.error((res.payload as ErrorDetail).detail.toString());
        }
        props.onSave?.(goal);
        return message.success('Goal updated successfully.');
    }

    const onFinish: FormProps<Goal>['onFinish'] = async () => {
        return props.goal ? onUpdate() : onCreate();
    };

    if (props.goal === undefined) {
        return null;
    }

    return (
        <Form
            layout={'vertical'}
            onFinish={onFinish}
        >
            <Form.Item<Goal>
                label="Name"
                name={'name'}
                rules={[{required: true, message: "Please enter a value."}]}
                initialValue={props.goal.name}
            >
                <Input onChange={event => {
                    setGoal({...goal, name: event.target.value})
                }} />
            </Form.Item>
            <Form.Item<Goal>
                label="Description"
                name={'description'}
                help={'Markdown is allowed.'}
                rules={[{required: true, message: "Please enter a value."}]}
                initialValue={props.goal.description}
            >
                <Input.TextArea onChange={event => {
                    setGoal({...goal, description: event.target.value})
                }} />
            </Form.Item>

            {templateFields.map((field, i) => (
                <FieldFormItem
                    key={`field-${i}`}
                    field={field}
                    obj={goal}
                    onChange={value => {
                        const newGoal = {...goal, [field.name]: value}
                        setGoal(newGoal);
                    }}/>
            ))}

            <Form.Item style={{marginTop: '36px'}}>
                <Button type="primary" htmlType={'submit'}>Save</Button>
            </Form.Item>
        </Form>
    );
}

export default GoalForm;
