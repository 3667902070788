import {Form} from 'antd';
import {SingleSelectField} from 'types';
import SingleSelectFormItem from 'components/forms/common/SingleSelectFormItem';

const SingleSelectFieldStep = (props: {
    field: SingleSelectField
    value: string,
    setValue: (value: string|null) => void,
}) => {
    return (
        <Form
            name="singleSelectFieldStep"
            initialValues={{value: props.value}}
            layout="vertical"
            autoComplete="off"
        >
            <SingleSelectFormItem {...props} />
        </Form>)
}

export default SingleSelectFieldStep;
