import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {App, ConfigProvider, theme} from 'antd';

import {useThemeMode} from 'hooks/useThemeMode';
import router from './router';

function MainApp() {
    const mode = useThemeMode();

    return (
        <ConfigProvider wave={{disabled: true}} theme={{
            algorithm: mode === 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm,
            token: {
                colorPrimary: '#01bd50',
                colorLink: '#01bd50',
            },
            components: {
                Table: {
                    headerColor: '#8c8c8c',
                    headerBg: 'transparent'
                }
            }
        }}>
            <App>
                <RouterProvider router={createBrowserRouter(router)}/>
            </App>
        </ConfigProvider>
    );
}

export default MainApp;
