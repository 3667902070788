import {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from 'store';
import {fetchPeople, selectPeople, selectPeopleStatus} from 'features/personSlice';
import {nameSort} from 'utils';
import type {Person} from 'types';

export function usePeople() {
    const dispatch = useAppDispatch();
    const people = useAppSelector(selectPeople) || [];
    const peopleStatus = useAppSelector(selectPeopleStatus);

    useEffect(() => {
        if (peopleStatus === 'idle') {
            dispatch(fetchPeople());
        }
    }, [peopleStatus, dispatch]);

    return [...people].sort(nameSort);
}

// FIXME: use the real API.
export function usePerson(id: string|undefined): Person|undefined {
    const people = usePeople();
    return id ? people.find(person => person.id === id) : undefined;
}
