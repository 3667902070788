import type {Role} from 'types';

import {usePerson} from 'hooks/usePeople';
import RoleDrawerDisplay from './RoleDrawerDisplay';
import RoleDrawerEdit from './RoleDrawerEdit';
import {useRole} from 'hooks/useRoles';


const RoleDrawerContents = (props: {
    role: Role | undefined,
    edit?: boolean,
    setEdit?: (value: boolean) => void
}) => {
    /* This redundancy forces a re-render on role data update. */
    const role = useRole(props.role?.id);
    const person = usePerson(role?.assignment?.id);

    if (!role) {
        return null;
    }

    if (props.edit) {
        return <RoleDrawerEdit role={role} person={person} onFinish={() => props.setEdit?.(false)} />;
    }

    return <RoleDrawerDisplay role={role} person={person}/>;
}

export default RoleDrawerContents;
