import React, {useState} from 'react';
import {App, Button, Form, FormProps, Input} from 'antd';
import {type ErrorDetail, type ErrorDetailDispatch, type ServiceAgreement} from 'types';
import {AppDispatch, useAppDispatch} from 'store';
import {createServiceAgreement, updateServiceAgreement} from 'features/serviceAgreementSlice';
import {useTemplate} from 'hooks/useTemplates';
import FieldFormItem from './common/FieldFormItem';

const ServiceAgreementForm = (props: {
    serviceAgreement?: ServiceAgreement,
    onSave?: (serviceAgreement: ServiceAgreement) => void,
    onCancel?: () => void;
}) => {
    const [serviceAgreement, setServiceAgreement] = useState<ServiceAgreement & {[p: string]: any}>(props.serviceAgreement ? props.serviceAgreement : {} as ServiceAgreement);
    const template = useTemplate(props.serviceAgreement?.template.id, props.serviceAgreement?.type);
    const templateFields = template ? template.fields : [];

    const dispatch: AppDispatch = useAppDispatch();
    const {message} = App.useApp();

    async function onCreate() {
        const res = await dispatch(createServiceAgreement(serviceAgreement));
        if ((res as ErrorDetailDispatch<ServiceAgreement>).error) {
            return message.error((res.payload as ErrorDetail).detail.toString());
        }
        props.onSave?.(serviceAgreement);
        return message.success('Service Agreement created successfully.');
    }

    async function onUpdate() {
        const res = await dispatch(updateServiceAgreement(serviceAgreement));
        if ((res as ErrorDetailDispatch<ServiceAgreement>).error) {
            return message.error((res.payload as ErrorDetail).detail.toString());
        }
        props.onSave?.(serviceAgreement);
        return message.success('Service Agreement updated successfully.');
    }

    const onFinish: FormProps<ServiceAgreement>['onFinish'] = async () => {
        return props.serviceAgreement ? onUpdate() : onCreate();
    };

    if (props.serviceAgreement === undefined) {
        return null;
    }

    return (
        <Form
            layout={'vertical'}
            onFinish={onFinish}
        >
            <Form.Item<ServiceAgreement>
                label="Name"
                name={'name'}
                rules={[{required: true, message: "Please enter a value."}]}
                initialValue={props.serviceAgreement.name}
            >
                <Input onChange={event => {
                    setServiceAgreement({...serviceAgreement, name: event.target.value})
                }} />
            </Form.Item>
            <Form.Item<ServiceAgreement>
                label="Description"
                name={'description'}
                help={'Markdown is allowed.'}
                rules={[{required: true, message: "Please enter a value."}]}
                initialValue={props.serviceAgreement.description}
            >
                <Input.TextArea onChange={event => {
                    setServiceAgreement({...serviceAgreement, description: event.target.value})
                }} />
            </Form.Item>

            {templateFields.map((field, i) => (
                <FieldFormItem
                    key={`field-${i}`}
                    field={field}
                    obj={serviceAgreement}
                    onChange={value => {
                        const newServiceAgreement = {...serviceAgreement, [field.name]: value}
                        setServiceAgreement(newServiceAgreement);
                    }}/>
            ))}

            <Form.Item style={{marginTop: '36px'}}>
                <Button type="primary" htmlType={'submit'}>Save</Button>
            </Form.Item>
        </Form>
    );
}

export default ServiceAgreementForm;
