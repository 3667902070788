import {
    UserOutlined,
    TeamOutlined,
    GlobalOutlined,
    TrophyOutlined,
    IdcardOutlined,
    LikeOutlined
} from '@ant-design/icons';
import {ObjectType} from 'types';
import {CSSProperties} from 'react';

const ObjectTypeIcon = (props: {
    type: ObjectType,
    style?: CSSProperties
}) => {
    if (props.type === 'person') {
        return <UserOutlined style={props.style} />
    }
    if (props.type === 'team') {
        return <TeamOutlined style={props.style} />
    }
    if (props.type === 'zone') {
        return <GlobalOutlined style={props.style} />;
    }
    if (props.type === 'goal') {
        return <TrophyOutlined style={props.style} />;
    }
    if (props.type === 'role') {
        return <IdcardOutlined style={props.style} />;
    }
    if (props.type === 'decision') {
        return <LikeOutlined style={props.style} />;
    }

    return null;
}

export default ObjectTypeIcon;
