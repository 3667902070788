import React from 'react';
import {Link} from 'react-router-dom';

import {Card, Row, Col, Table, Typography} from 'antd';
import type {ColumnsType} from 'antd/es/table';


import {asDataSource} from 'utils';
import {useTemplates} from 'hooks/useTemplates';
import CreateTemplateButton from './CreateTemplateButton';
import OrgCard from './OrgCard';

function createColumns() {
    const columns: ColumnsType = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, {id}) => <Link to={`/settings/templates/${id}`}><span className={'text-nowrap'}>{text}</span></Link>
        },
        {
            title: 'Type',
            dataIndex: 'objectType',
            key: 'objectType',
            render: text => text
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: text => text
        }
    ];
    return columns;
}

const SettingsPage = () => {
    const templates = useTemplates();
    return (
        <>
            <Row gutter={[16,16]}>
                <Col span={24}>
                    <OrgCard />
                </Col>
                <Col span={24}>
                <Card>
                    <Typography.Title level={2}>Templates</Typography.Title>
                    <Table
                        columns={createColumns()}
                        dataSource={asDataSource(templates)}
                        pagination={false}
                    />
                    <CreateTemplateButton
                        style={{margin: '1em 0'}}
                    />
                </Card>
                </Col>
            </Row>
        </>
    );
}

export default SettingsPage;
