import React, {useState} from 'react';
import {App, Button, Form, FormProps, Input} from 'antd';
import {type ErrorDetail, type ErrorDetailDispatch, type Role} from 'types';
import {AppDispatch, useAppDispatch} from 'store';
import {createRole, updateRole} from 'features/roleSlice';
import {useTemplate} from 'hooks/useTemplates';
import FieldFormItem from './common/FieldFormItem';
import PersonFormItem from './common/PersonFormItem';

const RoleForm = (props: {
    role?: Role,
    onSave?: (role: Role) => void,
    onCancel?: () => void;
}) => {
    const [role, setRole] = useState<Role & {[p: string]: any}>(props.role ? props.role : {} as Role);
    const template = useTemplate(props.role?.template.id, props.role?.type);
    const templateFields = template ? template.fields : [];

    const dispatch: AppDispatch = useAppDispatch();
    const {message} = App.useApp();

    async function onCreate() {
        const res = await dispatch(createRole(role));
        if ((res as ErrorDetailDispatch<Role>).error) {
            return message.error((res.payload as ErrorDetail).detail.toString());
        }
        props.onSave?.(role);
        return message.success('Role created successfully.');
    }

    async function onUpdate() {
        const res = await dispatch(updateRole(role));
        if ((res as ErrorDetailDispatch<Role>).error) {
            return message.error((res.payload as ErrorDetail).detail.toString());
        }
        props.onSave?.(role);
        return message.success('Role updated successfully.');
    }

    const onFinish: FormProps<Role>['onFinish'] = async () => {
        return props.role ? onUpdate() : onCreate();
    };

    if (props.role === undefined) {
        return null;
    }

    return (
        <Form
            layout={'vertical'}
            onFinish={onFinish}
        >
            <Form.Item<Role>
                label="Name"
                name={'name'}
                rules={[{required: true, message: "Please enter a value."}]}
                initialValue={props.role.name}
            >
                <Input onChange={event => {
                    setRole({...role, name: event.target.value})
                }} />
            </Form.Item>
            <Form.Item<Role>
                label="Accountabilities"
                name={'accountabilities'}
                help={'Markdown is allowed.'}
                rules={[{required: true, message: "Please enter a value."}]}
                initialValue={props.role.accountabilities}
            >
                <Input.TextArea onChange={event => {
                    setRole({...role, accountabilities: event.target.value})
                }} />
            </Form.Item>

            <PersonFormItem
                label={'Assignee'}
                personId={role.assignment?.id}
                setPersonId={personId => {
                    const newRole = {...role};
                    if (personId) {
                        newRole['assignment'] = {id: personId, type: 'person'};
                    } else {
                        newRole['assignment'] = null;
                    }
                    setRole(newRole);
                }}
                allowClear={true}
            />

            {templateFields.map((field, i) => (
                <FieldFormItem
                    key={`field-${i}`}
                    field={field}
                    obj={role}
                    onChange={value => {
                        const newRole = {...role, [field.name]: value}
                        setRole(newRole);
                    }}/>
            ))}

            <Form.Item style={{marginTop: '36px'}}>
                <Button type="primary" htmlType={'submit'}>Save</Button>
            </Form.Item>
        </Form>
    );
}

export default RoleForm;
