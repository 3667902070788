import {Form, Select} from 'antd';
import {ObjectRef, OneToOneField} from 'types';
import {buildObjectOptions} from 'utils';
import {useObjects} from 'hooks/useObjects';

const OneToOneFormItem = (props: {
    field: OneToOneField,
    objectRef: ObjectRef|undefined,
    setObjectRef: (ref: ObjectRef) => void;
}) => {

    const objs = useObjects(props.field.allowed);

    const options = buildObjectOptions(objs);
    const value = props.objectRef ? JSON.stringify({id:props.objectRef.id, type:props.objectRef.type}) : undefined;

    return (
        <Form.Item
            label={props.field.name}
            rules={[{required: props.field.required, message: 'Select a value.'}]}
            name={props.field.name}
            initialValue={value}
        >
            <Select
                options={options}
                onChange={value => props.setObjectRef(value ? JSON.parse(value) : null)}
                allowClear={!props.field.required}
                showSearch={true}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
            />
        </Form.Item>
    );
}

export default OneToOneFormItem;
