import {Form, Input} from 'antd';
import {titleCase} from '../../../utils';

const DescriptionStep = (props: {
    name: string,
    description: string,
    setDescription: (description: string) => void,
}) => {
    const [form] = Form.useForm<{ description: string|undefined }>();
    Form.useWatch(values => {
        if (values.description !== undefined) {
            props.setDescription(values.description);
        }
    }, form);

    return (
        <Form
            name="descriptionStep"
            form={form}
            style={{maxWidth: 600}}
            initialValues={{description: props.description}}
            layout="vertical"
            autoComplete="off"
        >
            <Form.Item<{description: string}>
                label={`${titleCase(props.name)} Description`}
                name="description"

                rules={[{required: true, message: `Please describe the ${props.name} in detail.`}]}
            >
                <Input.TextArea
                    rows={8}
                    placeholder={`Enter the ${props.name}.  Markdown is accepted.`}
                />
            </Form.Item>
        </Form>)
}

export default DescriptionStep;
