import React from 'react';
import {Link} from 'react-router-dom';

import {Flex, Table} from 'antd';
import type {ColumnsType} from 'antd/es/table';

import type {Template, Zone} from 'types';
import {asDataSource, map} from 'utils';
import {useZones} from 'hooks/useZones';
import {useTeams} from 'hooks/useTeams';
import CreateTeamButton from 'components/buttons/CreateTeamButton';
import {useTemplates} from 'hooks/useTemplates';

function createColumns(zones: Record<string, Zone>, templates: Record<string, Template>) {
    const columns: ColumnsType = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, {id}) => <Link to={`/teams/${id}`}>{text}</Link>
        },
        {
            title: 'Zone',
            key: 'zone',
            render: (_, {zone}) => {
                const parentZone = zone?.id ? zones[zone.id] : null;

                if (!parentZone) {
                    return zone.id;
                }

                return (
                    <Flex align={'center'} gap={'1rem'}>
                        <Link to={`/zones/${parentZone.id}`}>{parentZone.name}</Link>
                    </Flex>
                )
            }
        },
        {
            title: 'Purpose',
            dataIndex: 'purpose',
            key: 'purpose'
        },
        {
            title: 'Template',
            dataIndex: 'template',
            key: 'template',
            render: (_, {template}) => <Link to={`/settings/templates/${template.id}`}>{templates[template.id]?.name}</Link>
        }
    ];
    return columns;
}

const TeamsListPage = () => {
    const teams = useTeams();
    const zones = useZones();
    const templates = useTemplates('team');

    if (!templates || templates.length === 0) {
        return null;
    }

    return (
        <>
            <Table
                columns={createColumns(map(zones), map(templates))}
                dataSource={asDataSource(teams)}
                pagination={false}
            />
            <CreateTeamButton style={{marginTop:'1em'}} />
        </>
    );
}

export default TeamsListPage;
