import {useState} from 'react';
import {Button, Modal} from 'antd';

import {BaseObject, ObjectPictures} from 'types';
import styles from './styles.module.scss';

const AvatarPreviewModal = (props: {
    obj: BaseObject & ObjectPictures
    open: boolean
    onRemove: () => Promise<void>
    onChange: () => void
    onCancel: () => void
}) => {
    const [busy, setBusy] = useState(false);

    async function onRemove() {
        setBusy(true);
        await props.onRemove();
        setBusy(false);
    }

    return (
        <Modal
            className={styles.preview}
            title="Preview Avatar"
            open={props.open}
            onCancel={props.onCancel}
            destroyOnClose
            footer={[
                <Button key="remove" onClick={onRemove} loading={busy} disabled={busy}>
                    Remove
                </Button>,
                <Button key="change" onClick={props.onChange} disabled={busy}>
                    Change
                </Button>
            ]}
        >
            <img src={props.obj.picture_large!} alt={props.obj.name}/>
        </Modal>
    );
}

export default AvatarPreviewModal;
