import React, {CSSProperties} from 'react';
import {useNavigate} from 'react-router-dom';

import {Button, Flex} from 'antd';

const EditTemplateButton = (props: {
    templateId: string,
    style?: CSSProperties
}) => {
    const navigate = useNavigate();
    return (
        <Flex>
            <Button
                style={props.style}
                type={'default'}
                onClick={() => navigate(`/settings/templates/${props.templateId}/edit`)}
            >
                Edit Template
            </Button>
        </Flex>
    );
}

export default EditTemplateButton;
