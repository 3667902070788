import React from 'react';
import {Link} from 'react-router-dom';

import {Table} from 'antd';
import type {ColumnsType} from 'antd/es/table';

import {asDataSource} from 'utils';
import {useGoals} from 'hooks/useGoals';


const columns: ColumnsType = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, {id}) => {
            return (<Link to={`/goals/${id}`}>{text}</Link>);
        }
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    /*
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: text => (
            <Tag color={statusColor(text)} key={'status'}>
                {text.toUpperCase()}
            </Tag>
        )
    }
     */
];

const GoalsListPage = () => {
    const dataSource = asDataSource(useGoals());
    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
        />
    );
}

export default GoalsListPage;
