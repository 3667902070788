import React from 'react';
import {Link} from 'react-router-dom';

import {Table} from 'antd';
import type {ColumnsType} from 'antd/es/table';

import type {Zone, Template} from 'types';
import {asDataSource, map} from 'utils';
import {useZones} from 'hooks/useZones';
import {useTemplates} from 'hooks/useTemplates';
import CreateZoneButton from 'components/buttons/CreateZoneButton';

import styles from './ZonesListPage.module.scss';

function createColumns(templates: Record<string, Template>) {

    const columns: ColumnsType = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, {id}) => <Link to={`/zones/${id}`}>{text}</Link>,

            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: 'Boundary',
            dataIndex: 'boundary',
            key: 'boundary'
        },
        {
            title: 'Template',
            dataIndex: 'template',
            key: 'template',
            render: (_, {template}) => <Link to={`/settings/templates/${template.id}`}>{templates[template.id]?.name}</Link>
        }
    ];
    return columns;
}

function parentZones(zones: Zone[]) {
    return zones.filter(zone => !zone.parentZone?.id)
}

type TreeNode<T> = T & {
    parentId: string|null;
    children: TreeNode<T>[];
}

function treeify(zones: Zone[]) {
    const zoneMap: Record<string, TreeNode<Zone>> = {};

    for (const zone of zones) {
        zoneMap[zone.id] = {
            parentId: zone.parentZone?.id || null,
            children: [],
            ...zone
        }
    }
    for (const zone of zones) {
        if (zone.parentZone?.id) {
            const parent = zoneMap[zone.parentZone.id];
            parent.children.push(zoneMap[zone.id]);
        }
    }

    return zoneMap;
}

const ZonesListPage = () => {
    const zones = useZones();
    const templates = useTemplates('zone');
    if (!templates || templates.length === 0) {
        return null;
    }

    const zoneMap = treeify(zones);
    const columns = createColumns(map<Template>(templates));
    const dataSource = asDataSource(parentZones(zones));

    const expandedRowRender = (obj: any) => {
        const zones = zoneMap[(obj as Zone).id].children;
        return (
            <Table
                columns={columns}
                expandable={{ expandedRowRender, rowExpandable }}
                dataSource={asDataSource(zones)}
                pagination={false}
            />
        );
    }

    const rowExpandable = (obj: any) => {
        const zone = zoneMap[(obj as Zone).id];
        return zone.children && zone.children.length > 0;
    }

    return (
        <>
            <Table className={styles.table}
                columns={columns}
                expandable={{ expandedRowRender, rowExpandable }}
                dataSource={dataSource}
                pagination={false}
            />
            <CreateZoneButton style={{marginTop:'1em'}} />
        </>
    );
}

export default ZonesListPage;
