import {Select} from 'antd';

import type {TeamRef} from 'types';
import {asOptions} from 'utils';
import {useTeams} from 'hooks/useTeams';

const PartiesStep = (props: {
    parties: TeamRef[],
    setParties: (parties: TeamRef[]) => void,
}) => {
    const teams = useTeams();

    function onChange(values: string[]) {
        const parties = values.map(value => {
            return {id: value, type: 'team' as TeamRef['type']};
        });
        props.setParties(parties);
    }

    return (
        <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Please select"
            defaultValue={[]}
            onChange={onChange}
            options={asOptions(teams)}
        />
    )
}

export default PartiesStep;
