import {Form, Select} from 'antd';
import {MultiSelectField} from 'types';

const MultiSelectFormItem = (props: {
    field: MultiSelectField
    values: string[],
    setValues: (values: string[]) => void,
}) => {
    const options = props.field.options.map(option => {
        return {label: option, value: option};
    });

    return (
        <Form.Item
            label={props.field.name}
            rules={[{required: props.field.required, message: 'Select one or more values.'}]}
            name={props.field.name}
            initialValue={props.values}
        >
            <Select
                mode={'multiple'}
                options={options}
                onChange={props.setValues}
                allowClear={!props.field.required}
            />
        </Form.Item>
    );
}

export default MultiSelectFormItem;
