import {BaseObject, MultiSelectField} from 'types';
import DrawerDescription from './DrawerDescription';

const MultiSelectFieldDrawerDescription = (props: {
    obj: BaseObject & {[p:string]: any}
    field: MultiSelectField
}) => {
    const values: string[] | undefined = props.obj[props.field.name];
    if (!values) {
        return null;
    }

    return (
        <DrawerDescription title={props.field.name}>
            {values.map((value, i) => (
                <div key={`${props.field.name}-${i}`}>{value}</div>
            ))}
        </DrawerDescription>
    );
}

export default MultiSelectFieldDrawerDescription;
