import React from 'react';
import {Modal} from 'antd';


const CreateModal = (props: {
    open: boolean,
    title: string,
    onCancel: () => void,
    children: React.ReactNode,
    width?: string|number
}) => {
    const width = props.width ? props.width : 800;
    /* Destroy steps on close to reset the form content. */
    return (
        <Modal
            title={props.title}
            open={props.open}
            onCancel={props.onCancel}
            footer={null}
            destroyOnClose
            maskClosable={false}
            width={width}
            className='gg-modal'
        >
            {props.children}
        </Modal>
    );
}

export default CreateModal;
