import React from 'react';
import {Form, Select} from 'antd';

import {selectOptions} from 'utils';
import {usePeople} from 'hooks/usePeople';

const PersonFormItem = (props: {
    label: string,
    required?: boolean,
    personId?: string,
    setPersonId: (personId: string|undefined) => void;
    allowClear?: boolean
}) => {
    const people = usePeople();
    const options = selectOptions(people);

    return (
        <Form.Item
            label={props.label}
            rules={[{required: props.required, message: "Please enter a value."}]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
        >
            <Select
                placeholder="Please select"
                defaultValue={props.personId}
                onChange={props.setPersonId}
                options={options}
                allowClear={props.allowClear}
            />
        </Form.Item>
    )
}

export default PersonFormItem;
