import React from 'react';
import {useParams} from 'react-router-dom';

import {Card, Descriptions} from 'antd';

import type {ServiceAgreement, Template} from 'types';
import {map} from 'utils';
import {useTemplates} from 'hooks/useTemplates';

import MarkdownCard from 'components/MarkdownCard';
import Object from 'components/Object';

import MainContent from 'components/layout/MainContent';

import DeleteServiceAgreementButton from './DeleteServiceAgreementButton';
import {useServiceAgreement} from 'hooks/useServiceAgreements';


function items(serviceAgreement: ServiceAgreement, templates: Record<string, Template>) {
    return [
        {
            key: 'name',
            label: 'Name',
            children: serviceAgreement.name,
            span: 3
        }
    ]
}

const ServiceAgreementDetailPage = () => {
    const {serviceAgreementId} = useParams();
    const serviceAgreement = useServiceAgreement(serviceAgreementId || '')
    const templates = map<Template>(useTemplates());

    if (!serviceAgreement) { return null; }

    return (
        <MainContent>
            <Card>
                <Descriptions
                    title="Service Agreement Info"
                    items={items(serviceAgreement, templates)}
                    column={3}
                />
            </Card>

            {serviceAgreement.description ? <MarkdownCard markdown={serviceAgreement.description} /> : null}

            <Object object={serviceAgreement} />
            <DeleteServiceAgreementButton serviceAgreementId={serviceAgreement.id} />
        </MainContent>
    );
}

export default ServiceAgreementDetailPage;
