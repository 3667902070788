import React from 'react';
import {useParams} from 'react-router-dom';

import {Card, Descriptions} from 'antd';

import type {Decision, Template} from 'types';
import {map} from 'utils';
import {useTemplates} from 'hooks/useTemplates';

import MarkdownCard from 'components/MarkdownCard';
import Object from 'components/Object';

import MainContent from 'components/layout/MainContent';

import DeleteDecisionButton from './DeleteDecisionButton';
import {useDecision} from 'hooks/useDecisions';


function items(decision: Decision, templates: Record<string, Template>) {
    return [
        {
            key: 'name',
            label: 'Name',
            children: decision.name,
            span: 3
        }
    ]
}

const DecisionDetailPage = () => {
    const {decisionId} = useParams();
    const decision = useDecision(decisionId || '')
    const templates = map<Template>(useTemplates());

    if (!decision) { return null; }

    return (
        <MainContent>
            <Card>
                <Descriptions
                    title="Decision Info"
                    items={items(decision, templates)}
                    column={3}
                />
            </Card>

            {decision.description ? <MarkdownCard markdown={decision.description} /> : null}

            <Object object={decision} />
            <DeleteDecisionButton decisionId={decision.id} />
        </MainContent>
    );
}

export default DecisionDetailPage;
