import {useState} from 'react';
import {App, Modal} from 'antd';

import {BaseObject, ObjectPictures} from 'types';
import apiClient from 'api';
import {getErrorMessage} from 'utils';
import {useOrg} from 'hooks/useOrg';

import AvatarDropzone from './AvatarDropzone';
import AvatarCrop from './AvatarCrop';

const AvatarEditModal = (props: {
    obj: BaseObject & ObjectPictures
    open: boolean
    onChange: (obj: BaseObject & ObjectPictures) => Promise<void>
    onCancel: () => void
}) => {
    const [busy, setBusy] = useState(false);
    const {message} = App.useApp();

    const org = useOrg();

    const [imageData, setImageData] = useState<ArrayBuffer>();
    const [croppedData, setCroppedData] = useState<string>();

    const handleOk = async () => {
        setBusy(true);
        if (org) {
            try {
                const pictures = await apiClient.post<ObjectPictures>(
                    `/org/${org.id}/upload`,
                    {
                        type: props.obj.type,
                        id: props.obj.id,
                        image: croppedData
                    }
                );

                setImageData(undefined);
                setCroppedData(undefined);

                const obj = {...props.obj, ...pictures};
                await props.onChange(obj);
            } catch (e) {
                return message.error(getErrorMessage(e));
            } finally {
                setBusy(false);
            }
        }
    };

    const handleCancel = () => {
        setImageData(undefined);
        setCroppedData(undefined);
        props.onCancel();
    };

    const onDrop = (file: File) => {
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
            setImageData(reader.result as ArrayBuffer);
        }
        reader.readAsArrayBuffer(file)
    }

    const onCrop = (data: string) => {
        setCroppedData(data);
    }

    return (
        <Modal
            title="Edit Avatar"
            open={props.open}
            onOk={handleOk}
            okButtonProps={{disabled: !imageData || busy, loading: busy}}
            onCancel={handleCancel}
            destroyOnClose
        >
            {imageData ? <AvatarCrop bytes={imageData} onCrop={onCrop}/> : <AvatarDropzone onDrop={onDrop}/>}
        </Modal>
    );
}

export default AvatarEditModal;
