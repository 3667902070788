import React, {useState} from 'react';
import {App, Button, Form, FormProps, Input} from 'antd';
import {type ErrorDetail, type ErrorDetailDispatch, type Team} from 'types';
import {AppDispatch, useAppDispatch} from 'store';
import {createTeam, updateTeam} from 'features/teamSlice';
import {useTemplate} from 'hooks/useTemplates';
import FieldFormItem from './common/FieldFormItem';

const TeamForm = (props: {
    team?: Team,
    onSave?: (team: Team) => void,
    onCancel?: () => void;
}) => {
    const [team, setTeam] = useState<Team & {[p: string]: any}>(props.team ? props.team : {} as Team);
    const template = useTemplate(props.team?.template.id, props.team?.type);
    const templateFields = template ? template.fields : [];

    const dispatch: AppDispatch = useAppDispatch();
    const {message} = App.useApp();

    async function onCreate() {
        const res = await dispatch(createTeam(team));
        if ((res as ErrorDetailDispatch<Team>).error) {
            return message.error((res.payload as ErrorDetail).detail.toString());
        }
        props.onSave?.(team);
        return message.success('Team created successfully.');
    }

    async function onUpdate() {
        const res = await dispatch(updateTeam(team));
        if ((res as ErrorDetailDispatch<Team>).error) {
            return message.error((res.payload as ErrorDetail).detail.toString());
        }
        props.onSave?.(team);
        return message.success('Team updated successfully.');
    }

    const onFinish: FormProps<Team>['onFinish'] = async () => {
        return props.team ? onUpdate() : onCreate();
    };

    if (props.team === undefined) {
        return null;
    }

    return (
        <Form
            layout={'vertical'}
            onFinish={onFinish}
        >
            <Form.Item<Team>
                label="Name"
                name={'name'}
                rules={[{required: true, message: "Please enter a value."}]}
                initialValue={props.team.name}
            >
                <Input onChange={event => {
                    setTeam({...team, name: event.target.value})
                }} />
            </Form.Item>
            <Form.Item<Team>
                label="Purpose"
                name={'purpose'}
                rules={[{required: true, message: "Please enter a value."}]}
                help={'Enter a short-form purpose e.g. 144 characters.'}
                initialValue={props.team.purpose}
            >
                <Input.TextArea rows={4} onChange={event => {
                    setTeam({...team, purpose: event.target.value})
                }} />
            </Form.Item>

            {templateFields.map((field, i) => (
                <FieldFormItem
                    key={`field-${i}`}
                    field={field}
                    obj={team}
                    onChange={value => {
                        const newTeam = {...team, [field.name]: value}
                        setTeam(newTeam);
                    }}/>
            ))}

            <Form.Item style={{marginTop: '36px'}}>
                <Button type="primary" htmlType={'submit'}>Save</Button>
            </Form.Item>
        </Form>
    );
}

export default TeamForm;
