import {Row, Col, Card} from 'antd';

import type {ServiceAgreement, Team} from 'types';

import {useTeamServiceAgreements} from 'hooks/useServiceAgreements';
import AddServiceAgreementButton from './AddServiceAgreementButton';
import ServiceAgreementCard from './ServiceAgreementCard';

const AgreementsTab = (props: {
    team: Team
    onClick?: (agreement: ServiceAgreement) => void
}) => {
    const serviceAgreements = useTeamServiceAgreements(props.team)
    return (
        <div>
            <Card type={'inner'} title={'Service Agreements'} extra={<AddServiceAgreementButton team={props.team}/>}>
                <Row gutter={16}>
                    {serviceAgreements.map(serviceAgreement => (
                        <Col span={12} key={serviceAgreement.id}>
                            <ServiceAgreementCard serviceAgreement={serviceAgreement} onClick={() => props.onClick?.(serviceAgreement)}/>
                        </Col>
                    ))}
                </Row>
            </Card>
        </div>
    );
}

export default AgreementsTab;
