import {Field} from 'types';
import CreateModal from 'components/steps/_common/CreateModal';

import FieldForm from './FieldForm';


const EditFieldModal = (props: {
    title: string
    field?: Field,
    open: boolean,
    onSave: (field: Field) => void
    onCancel?: () => void
}) => {
    return (
        <CreateModal
            title={props.title}
            open={props.open}
            onCancel={() => props.onCancel?.()}
        >
            <FieldForm field={props.field} onFinish={props.onSave} />
        </CreateModal>
    );
}

export default EditFieldModal;
