import {Team} from 'types';
import CreateButton from 'components/steps/_common/CreateButton';
import CreateServiceAgreementSteps from 'components/steps/serviceAgreements/CreateServiceAgreementSteps';

const AddServiceAgreementButton = (props: {
    team: Team
}) => {
    return (
        <CreateButton
            title={'Add Service Agreement'}
        >
            {(onSuccess, onCancel) => (
                <CreateServiceAgreementSteps team={props.team} onSuccess={onSuccess} onCancel={onCancel} />
            )}
        </CreateButton>
    );
}

export default AddServiceAgreementButton;
