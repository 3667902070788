import React from 'react';

import {UIMatch, useNavigation} from 'react-router-dom';
import {useMatches} from 'react-router-dom';

import type {ItemType} from 'antd/es/breadcrumb/Breadcrumb';

import {trimSlashes} from 'utils';

import {useOrg} from 'hooks/useOrg';
import Loading from 'components/Loading';
import SelectOrgPage from './selectOrg/SelectOrgPage';

import MainLayout from './MainLayout';

type RouteHandle = {
    title?: string;
    breadcrumbs?: (data: any) => ItemType[]
};

function title(matches: UIMatch[]): string | undefined {
    const lastRoute = matches[matches.length - 1];
    return trimSlashes((lastRoute.handle as RouteHandle | undefined)?.title);
}

function breadCrumbItems(matches: UIMatch[]) {
    // const lastRoute = matches[matches.length - 1];
    // FIXME: build breadcrumbs from lastRoute.params
    return undefined
}

const MainPage = () => {
    const matches = useMatches();
    const breadcrumbs = breadCrumbItems(matches);
    const pageTitle = title(matches);
    const navigation = useNavigation();
    const org = useOrg();

    if (navigation.state === 'loading') {
        return <Loading />
    }
    return org ? <MainLayout pageTitle={pageTitle} breadcrumbs={breadcrumbs} /> : <SelectOrgPage />;
}

export default MainPage;
