import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import type {Goal, SliceStatus, Organization, ErrorDetail} from 'types';
import {CLEAR_ORG_ACTION, LOGOUT_ACTION, RootState} from 'store';
import apiClient from 'api';


type GoalsState = {
    entities: Goal[],
    status: SliceStatus,
    error: string,
};

export const fetchGoals = createAsyncThunk<Goal[]>('goals/fetch', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    return await apiClient.get(`/org/${state.org.value.id}/goals`);
});

export const deleteGoal = createAsyncThunk<Goal, string, {
    rejectValue: ErrorDetail,
}>('Goal/delete', async (GoalId, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    try {
        return await apiClient.delete<Goal>(`/org/${state.org.value.id}/goals/${GoalId}`);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

export const createGoal = createAsyncThunk<Goal, Goal, {
    rejectValue: ErrorDetail,
}>('goal/create', async (Goal, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    try {
        return await apiClient.post(`/org/${state.org.value.id}/goals`, Goal);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

type UpdateGoal = Partial<Goal> & {id: string};
export const updateGoal = createAsyncThunk<Goal, UpdateGoal, {
    rejectValue: ErrorDetail,
}>('goal/update', async (data, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    try {
        return await apiClient.patch(`/org/${state.org.value.id}/goals/${data.id}`, data);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

const INITIAL_STATE = {
    entities: [],
    status: 'idle' as GoalsState['status'],
    error: ''
};

// noinspection JSUnusedGlobalSymbols
const GoalSlice = createSlice({
    name: 'goals',
    initialState: INITIAL_STATE satisfies GoalsState as GoalsState,
    reducers: {
        clearError: (state) => {
            state.error = '';
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchGoals.pending, (state: GoalsState) => {
                state.status = 'pending';
            })
            .addCase(fetchGoals.fulfilled, (state: GoalsState, action: PayloadAction<Goal[]>) => {
                state.status = 'succeeded';
                state.entities = action.payload as Goal[];
            })
            .addCase(fetchGoals.rejected, (state: GoalsState, action) => {
                state.status = 'failed';
                //state.error = action.error.message;
                console.log(action);
            })
            .addCase(createGoal.fulfilled, (state: GoalsState, action: PayloadAction<Goal|ErrorDetail>) => {
                state.entities.push(action.payload as Goal);
            })
            .addCase(deleteGoal.fulfilled, (state: GoalsState, action) => {
                state.entities = state.entities.filter((Goal: Goal) => Goal.id !== action.payload.id);
            })
            .addCase(CLEAR_ORG_ACTION, (state: GoalsState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(LOGOUT_ACTION, (state: GoalsState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(updateGoal.fulfilled, (state: GoalsState, action: PayloadAction<Goal|ErrorDetail>) => {
                const Goal = action.payload as Goal;
                const index = state.entities.findIndex(r => r.id === Goal.id);
                state.entities[index] = Goal;
            })
    }
});

export const selectGoals = (state: RootState) => state.goals.entities;
export const selectGoalStatus = (state: RootState) => state.goals.status;

export default GoalSlice.reducer;
