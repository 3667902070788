import {Form} from 'antd';
import {ObjectRef, OneToOneField} from 'types';

import OneToOneFormItem from 'components/forms/common/OneToOneFormItem';

function initialValue(ref: ObjectRef|undefined) {
    if (ref) {
        return JSON.stringify(ref);
    }
    return undefined;
}

const OneToOneFieldStep = (props: {
    field: OneToOneField
    objectRef: ObjectRef|undefined,
    setObjectRef: (value: ObjectRef) => void
}) => {
    const [form] = Form.useForm<{ value: string }>();
    Form.useWatch(values => {
        if (values.value !== undefined) {
            props.setObjectRef(JSON.parse(values.value));
        }
    }, form);

    return (
        <Form
            name="oneToOneFieldStep"
            form={form}
            style={{maxWidth: 600}}
            initialValues={{value: initialValue(props.objectRef)}}
            layout="vertical"
            autoComplete="off"
        >
            <OneToOneFormItem {...props} />
        </Form>)
}

export default OneToOneFieldStep;
