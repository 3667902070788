import {BaseObject, ObjectRef, OneToOneField} from 'types';
import {titleCase} from 'utils';
import {useObjects} from 'hooks/useObjects';
import ObjectTypeIcon from 'components/ObjectTypeIcon';
import DrawerDescription from './DrawerDescription';

const OneToOneFieldDrawerDescription = (props: {
    obj: BaseObject & {[p:string]: any}
    field: OneToOneField
}) => {
    const objects = useObjects(props.field.allowed);

    const ref = props.obj[props.field.name] as ObjectRef|undefined;
    if (!ref) {
        return null;
    }

    const value = objects[ref.type].find(obj => obj.id === ref.id);
    if (!value) {
        return (
            <DrawerDescription title={props.field.name}>
                {titleCase(ref.type)}[{ref.id}] Not Found!  Please report this error.
            </DrawerDescription>
        );
    }

    return (
        <DrawerDescription title={props.field.name}>
            <ObjectTypeIcon style={{marginRight: '.25em'}} type={ref.type}/> {value.name}
        </DrawerDescription>
    );
}

export default OneToOneFieldDrawerDescription;
