import {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from 'store';

import {ServiceAgreement, Team} from 'types';
import {nameSort} from 'utils';
import {fetchServiceAgreements, selectServiceAgreements, selectServiceAgreementStatus} from 'features/serviceAgreementSlice';

export function useServiceAgreements(): ServiceAgreement[] {
    const dispatch = useAppDispatch();
    const serviceAgreements = useAppSelector(selectServiceAgreements);
    const serviceAgreementStatus = useAppSelector(selectServiceAgreementStatus);

    useEffect(() => {
        if (serviceAgreementStatus === 'idle') {
            dispatch(fetchServiceAgreements() as any);
        }
    }, [serviceAgreementStatus, dispatch]);

    return [...serviceAgreements].sort(nameSort);
}

export function useServiceAgreement(serviceAgreementId: string|undefined) {
    const serviceAgreements = useServiceAgreements();
    return serviceAgreementId ? serviceAgreements.find(serviceAgreement => serviceAgreement.id === serviceAgreementId) : null;
}

export function useTeamServiceAgreements(team: Team|null): ServiceAgreement[] {
    const serviceAgreements = useServiceAgreements();
    return team ? serviceAgreements.filter(serviceAgreement => serviceAgreement.team.id === team.id) : [];
}
