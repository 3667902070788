import React from 'react';
import {Dropdown} from 'antd';

import AnimatedCircle from './AnimatedCircle';
import NodeActions from './NodeActions';
import {NodeProps} from './types';


const TeamNode = (props: NodeProps) => {
    return (
        <Dropdown
            menu={{
                items: NodeActions.teamMenuItems,
                onClick: menu => NodeActions.handleMenuClick(menu, props)
            }}
            trigger={['contextMenu']}
        >
            <AnimatedCircle
                cx={props.cx}
                cy={props.cy}
                r={props.r}
                onClick={event => NodeActions.onClick(event, props)}
                onDoubleClick={event => NodeActions.onDoubleClick(event, props)}
                onContextMenu={event => event.stopPropagation()}
                stroke={'#01bd50'}
                fill={'#01bd50'}
            />
        </Dropdown>
    );
}

export default TeamNode;
