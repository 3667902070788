import {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from 'store';

import {Decision, Team} from 'types';
import {nameSort} from 'utils';
import {fetchDecisions, selectDecisions, selectDecisionStatus} from 'features/decisionSlice';

export function useDecisions(): Decision[] {
    const dispatch = useAppDispatch();
    const decisions = useAppSelector(selectDecisions);
    const decisionStatus = useAppSelector(selectDecisionStatus);

    useEffect(() => {
        if (decisionStatus === 'idle') {
            dispatch(fetchDecisions() as any);
        }
    }, [decisionStatus, dispatch]);

    return [...decisions].sort(nameSort);
}

export function useDecision(decisionId: string|undefined) {
    const decisions = useDecisions();
    return decisionId ? decisions.find(decision => decision.id === decisionId) : null;
}

export function useTeamDecisions(team: Team|null): Decision[] {
    const decisions = useDecisions();
    return team ? decisions.filter(decision => decision.team.id === team.id) : [];
}
