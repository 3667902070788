import {SingleSelectField, MultiSelectField} from 'types';
import OptionsTable from './OptionsTable';

const SelectField = (props: {
    field: SingleSelectField | MultiSelectField,
    setField: (field: SingleSelectField|MultiSelectField) => void,
}) => {
    function setOptions(options: string[]|undefined) {
        options = options ? options : [];
        props.setField({...props.field, options});
    }

    return (
        <OptionsTable
            options={props.field.options}
            setOptions={setOptions}
        />
    );
}

export default SelectField;
