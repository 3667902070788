import React, {useState} from 'react';
import {useElementSize} from '@custom-react-hooks/use-element-size';

import type {Zone, Team, Role, Person, Organization} from 'types';

import type {GraphNode} from './types';
import {map} from 'utils';

import Loading from 'components/Loading';

import GraphComponent from './GraphComponent';
import NodeActions, {type NodeAction} from './NodeActions';

import {useZoneChartData} from 'hooks/useData';
import NoData from './NoData';
import ObjectDrawer from '../../components/drawers/ObjectDrawer';


function treeify(org: Organization, zones: Zone[], teams: Team[], roles: Role[], people: Record<string, Person>) {
    const graph: Record<string, GraphNode> = {}

    for (const zone of zones) {
        graph[zone.id] = {
            obj: zone,
            children: [],
            parentId: null,
            value: 2
        }
    }

    for (const zone of zones) {
        if (zone.parentZone?.id) {
            graph[zone.parentZone?.id].children.push(graph[zone.id]);
            graph[zone.id].parentId = zone.parentZone?.id || null;
        }
    }

    for (const team of teams) {
        graph[team.id] = {
            obj: team,
            children: [],
            parentId: null,
            value: 2
        }
    }

    for (const team of teams) {
        if (team.zone?.id) {
            graph[team.zone?.id].children.push(graph[team.id]);
            graph[team.id].parentId = team.zone?.id || null;
        }
    }

    for (const role of roles) {
        const user = role.assignment?.id ? people[role.assignment.id] : undefined;
        graph[role.team.id].children.push({
            obj: role,
            value: 1,
            children: [],
            parentId: role.team.id,
            user: user
        });
    }

    const top = {
        obj: org,
        children: [] as GraphNode[],
        parentId: null,
    }

    for (const zone of zones) {
        if (graph[zone.id].parentId === null) {
            graph[zone.id].parentId = org.id;
            top.children.push(graph[zone.id]);
        }
    }
    return top as GraphNode;
}

const ZoneChartPage = () => {
    const [setRef, size] = useElementSize();
    const [node, setNode] = useState<GraphNode|undefined>();
    const [drawerOpen, setDrawerOpen] = useState(false)

    const [action, setAction] = useState<NodeAction>();
    const objs = useZoneChartData();

    if (!objs) {
        return (<Loading/>);
    }

    if (objs['zones'].length === 0) {
        return (<NoData />)
    }


    function onSelect(node: GraphNode|undefined) {
        setNode(node);
        setDrawerOpen(true);
    }

    const people = map(objs['people']);
    const data = treeify(objs['org'], objs['zones'], objs['teams'], objs['roles'], people);

    return (
        <>
            <div ref={setRef} style={{height: '100%', width: '100%', padding:0, margin:0, boxSizing:'border-box'}}>
                {size.width && size.height && data ?
                    <GraphComponent
                        data={data}
                        width={size.width}
                        height={size.height}
                        onSelect={onSelect}
                        onAction={(action, node) => setAction({
                            action: action as NodeAction['action'], node
                        })}
                    /> :
                    null
                }
            </div>
            <div style={{position:'absolute', bottom:'4px', left:'8px'}}>{size.width}x{size.height}</div>
            {!!node?.obj ?
                <ObjectDrawer
                    obj={node?.obj}
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                /> : null
            }
            <NodeActions action={action} onClose={() => setAction(undefined)} />
        </>
    );
}

export default ZoneChartPage;
