import {Form} from 'antd';
import {TextField} from 'types';
import TextFormItem from 'components/forms/common/TextFormItem';

const TextFieldStep = (props: {
    field: TextField
    value: string,
    setValue: (value: string|null) => void,
}) => {
    return (
        <Form
            name="textFieldStep"
            style={{maxWidth: 600}}
            initialValues={{value: props.value}}
            layout="vertical"
            autoComplete="off"
        >
            <TextFormItem {...props} />
        </Form>)
}


export default TextFieldStep;
