import React, {CSSProperties} from 'react';
import {useNavigate} from 'react-router-dom';

import {Button} from 'antd';

const CreateTemplateButton = (props: {
    style?: CSSProperties
}) => {
    const navigate = useNavigate();
    return (
        <Button
            style={props.style}
            type={'primary'}
            onClick={() => navigate('/settings/create-template')}
        >
            Create Template
        </Button>
    );
}

export default CreateTemplateButton;
