import {Form} from 'antd';
import {MultiSelectField} from 'types';
import MultiSelectFormItem from 'components/forms/common/MultiSelectFormItem';

const MultiSelectFieldStep = (props: {
    field: MultiSelectField
    values: string[],
    setValues: (values: string[]) => void,
}) => {
    return (
        <Form
            name="multiSelectFieldStep"
            style={{maxWidth: 600}}
            initialValues={{values: props.values}}
            layout="vertical"
            autoComplete="off"
        >
            <MultiSelectFormItem {...props} />
        </Form>)
}

export default MultiSelectFieldStep;
