import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

import type {Person} from 'types';
import {RootState} from 'store';

const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState: {
        user: null as Person | null
    },
    reducers: {
        setCurrentUser: (state, action: PayloadAction<Person|null>) => {
            state.user = action.payload;
        }
    }
});

export const { setCurrentUser } = currentUserSlice.actions;
export const getCurrentUser = (state: RootState) => state.current.user;

export default currentUserSlice.reducer;
