import AddGoalButton from './AddGoalButton';
import {Flex, Row, Col} from 'antd';

import type {Goal, Team} from 'types';

import {useTeamGoals} from 'hooks/useGoals';

import GoalCard from './GoalCard';

const GoalsTab = (props: {
    team: Team
    onClick?: (goal: Goal) => void
}) => {
    const goals = useTeamGoals(props.team)
    return (
        <div>
            <Flex className='FixTabBtn'>
                <AddGoalButton team={props.team}/>
            </Flex>
            <Row gutter={8}>
                {goals.map(goal => (
                    <Col span={12} key={goal.id}>
                        <GoalCard goal={goal} onClick={() => props.onClick?.(goal)}/>
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default GoalsTab;
