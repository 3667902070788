import {
    BaseObject,
    Field,
    MultiSelectField,
    ObjectRef,
    OneToManyField,
    OneToOneField,
    SingleSelectField,
    TextField
} from 'types';
import TextFormItem from './TextFormItem';
import SingleSelectFormItem from './SingleSelectFormItem';
import MultiSelectFormItem from './MultiSelectFormItem';
import OneToOneFormItem from './OneToOneFormItem';
import OneToManyFormItem from './OneToManyFormItem';

const FieldFormItem = (props: {
    field: Field,
    obj: BaseObject & {[p: string]: any},
    onChange: (value: any) => void,
}) => {

    if (props.field.type === 'text') {
        return <TextFormItem
            field={props.field as TextField}
            value={props.obj[props.field.name] as string}
            setValue={props.onChange}
        />
    }

    if (props.field.type === 'single-select') {
        return <SingleSelectFormItem
            field={props.field as SingleSelectField}
            value={props.obj[props.field.name] as string}
            setValue={props.onChange}
        />
    }

    if (props.field.type === 'multi-select') {
        return <MultiSelectFormItem
            field={props.field as MultiSelectField}
            values={props.obj[props.field.name] as string[]}
            setValues={props.onChange}
        />
    }

    if (props.field.type === 'one-to-one') {
        return <OneToOneFormItem
            field={props.field as OneToOneField}
            objectRef={props.obj[props.field.name] as ObjectRef}
            setObjectRef={props.onChange}
        />
    }

    if (props.field.type === 'one-to-many') {
        return <OneToManyFormItem
            field={props.field as OneToManyField}
            objectRefs={props.obj[props.field.name] as ObjectRef[]}
            setObjectRefs={props.onChange}
        />
    }

    return null;
}

export default FieldFormItem;
