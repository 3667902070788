import CreateRoleSteps from 'components/steps/roles/CreateRoleSteps';
import CreateModal from 'components/steps/_common/CreateModal';

import type {GraphNode, NodeProps} from './types';
import {Team, Zone, Role, ObjectType} from 'types';
import CreateZoneSteps from 'components/steps/zones/CreateZoneSteps';
import CreateTeamSteps from 'components/steps/teams/CreateTeamSteps';
import AssignPersonToRoleSteps from 'components/steps/roles/AssignPersonToRoleSteps';
import type {MenuProps} from 'antd';
import React from 'react';



export type NodeAction = {
    action: 'add-role'|'add-zone'|'add-team'|'assign-user-to-role',
    node: GraphNode,
}

export const zoneMenuItems: MenuProps['items'] = [
    {
        label: 'Add Zone',
        key: 'add-zone',
    },
    {
        label: 'Add Team',
        key: 'add-team',
    }
];

export const teamMenuItems: MenuProps['items'] = [
    {
        label: 'Add Role',
        key: 'add-role',
    }
];

export const roleMenuItems: MenuProps['items'] = [
    {
        label: 'Assign Role',
        key: 'assign-user-to-role',
    }
];

export function getByType(objectType: ObjectType) {
    switch (objectType) {
        case 'zone':
            return zoneMenuItems;
        case 'team':
            return teamMenuItems;
    }
    return undefined
}

export function onClick(event: React.MouseEvent, props: NodeProps) {
    event.preventDefault();
    event.stopPropagation();
    props.onClick?.(props.node);
}

function onDoubleClick(event: React.MouseEvent, props: NodeProps) {
    event.preventDefault();
    event.stopPropagation();
    props.onDoubleClick?.(props.node);
}

function handleMenuClick(
    info: {key: string, domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>},
    props: NodeProps
) {
    info.domEvent.stopPropagation();
    props.onAction?.(info.key, props.node);
}

const NodeActions = (props: {
    action: NodeAction|undefined
    onClose: () => void;
}) => {
    return (
        <div>
            <CreateModal
                title={`Add role to ${props.action?.node.obj.name}`}
                open={props.action?.action === 'add-role'}
                onCancel={props.onClose}
            >
                <CreateRoleSteps
                    team={props.action?.node.obj as Team|undefined}
                    onSuccess={props.onClose}
                    onCancel={props.onClose}
                />
            </CreateModal>
            <CreateModal
                title={`Add zone to ${props.action?.node.obj.name}`}
                open={props.action?.action === 'add-zone'}
                onCancel={props.onClose}
            >
                <CreateZoneSteps
                    zone={props.action?.node.obj as Zone|undefined}
                    onSuccess={props.onClose}
                    onCancel={props.onClose}
                />
            </CreateModal>
            <CreateModal
                title={`Add team to ${props.action?.node.obj.name}`}
                open={props.action?.action === 'add-team'}
                onCancel={props.onClose}
            >
                <CreateTeamSteps
                    zone={props.action?.node.obj as Zone|undefined}
                    onSuccess={props.onClose}
                    onCancel={props.onClose}
                />
            </CreateModal>
            <CreateModal
                title={`Assign User to ${props.action?.node.obj.name}`}
                open={props.action?.action === 'assign-user-to-role'}
                onCancel={props.onClose}
                width={600}
            >
                <AssignPersonToRoleSteps
                    role={props.action?.node.obj as Role}
                    onSuccess={props.onClose}
                    onCancel={props.onClose}
                />
            </CreateModal>
        </div>
    );
}

NodeActions.getByType = getByType;
NodeActions.zoneMenuItems = zoneMenuItems;
NodeActions.teamMenuItems = teamMenuItems;
NodeActions.roleMenuItems = roleMenuItems;

NodeActions.onClick = onClick;
NodeActions.onDoubleClick = onDoubleClick;
NodeActions.handleMenuClick = handleMenuClick;

export default NodeActions;
