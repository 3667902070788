import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import type {Zone, SliceStatus, Organization, ErrorDetail} from 'types';
import {CLEAR_ORG_ACTION, LOGOUT_ACTION, RootState} from 'store';
import apiClient from 'api';


type ZonesState = {
    entities: Zone[],
    status: SliceStatus,
    error: string,
};

export const fetchZones = createAsyncThunk<Zone[]>('zones/fetch', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    return await apiClient.get(`/org/${state.org.value.id}/zones`);
});

export const deleteZone = createAsyncThunk<Zone, string, {
    rejectValue: ErrorDetail,
}>('zone/delete', async (userId, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    try {
        return await apiClient.delete<Zone>(`/org/${state.org.value.id}/zones/${userId}`);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

export const createZone = createAsyncThunk<Zone, Zone, {
    rejectValue: ErrorDetail,
}>('zone/create', async (zone, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    try {
        return await apiClient.post(`/org/${state.org.value.id}/zones`, zone);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

type UpdateZone = Partial<Zone> & {id: string};
export const updateZone = createAsyncThunk<Zone, UpdateZone, {
    rejectValue: ErrorDetail,
}>('zone/update', async (data, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    try {
        return await apiClient.patch(`/org/${state.org.value.id}/zones/${data.id}`, data);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

const INITIAL_STATE = {
    entities: [],
    status: 'idle' as ZonesState['status'],
    error: ''
};

// noinspection JSUnusedGlobalSymbols
const zoneSlice = createSlice({
    name: 'zones',
    initialState: INITIAL_STATE satisfies ZonesState as ZonesState,
    reducers: {
        clearError: (state) => {
            state.error = '';
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchZones.pending, (state: ZonesState) => {
                state.status = 'pending';
            })
            .addCase(fetchZones.fulfilled, (state: ZonesState, action: PayloadAction<Zone[]>) => {
                state.status = 'succeeded';
                state.entities = action.payload as Zone[];
            })
            .addCase(fetchZones.rejected, (state: ZonesState, action) => {
                state.status = 'failed';
                //state.error = action.error.message;
                console.log(action);
            })
            .addCase(createZone.fulfilled, (state: ZonesState, action: PayloadAction<Zone|ErrorDetail>) => {
                state.entities.push(action.payload as Zone);
            })
            .addCase(deleteZone.fulfilled, (state: ZonesState, action) => {
                state.entities = state.entities.filter((user: Zone) => user.id !== action.payload.id);
            })
            .addCase(updateZone.fulfilled, (state: ZonesState, action: PayloadAction<Zone|ErrorDetail>) => {
                const zone = action.payload as Zone;
                const index = state.entities.findIndex(r => r.id === zone.id);
                Object.assign(state.entities[index], zone);
                state.entities = [...state.entities];
            })
            .addCase(CLEAR_ORG_ACTION, (state: ZonesState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(LOGOUT_ACTION, (state: ZonesState) => {
                Object.assign(state, INITIAL_STATE);
            })
    }
});

export const selectZones = (state: RootState) => state.zones.entities;
export const selectZoneStatus = (state: RootState) => state.zones.status;

export default zoneSlice.reducer;
