import {Flex, Row, Col} from 'antd';

import type {Decision, Team} from 'types';

import {useTeamDecisions} from 'hooks/useDecisions';

import DecisionCard from './DecisionCard';
import AddDecisionButton from './AddDecisionButton';

const DecisionsTab = (props: {
    team: Team,
    onClick?: (decision: Decision) => void
}) => {
    const decisions = useTeamDecisions(props.team)
    return (
        <div>
            <Flex className='FixTabBtn' >
                <AddDecisionButton team={props.team}/>
            </Flex>
            <Row gutter={8}>
                {decisions.map(decision => (
                    <Col span={12} key={decision.id}>
                        <DecisionCard decision={decision} onClick={() => props.onClick?.(decision)}/>
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default DecisionsTab;
