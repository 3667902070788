import type {ServiceAgreement} from 'types';

import {useServiceAgreement} from 'hooks/useServiceAgreements';
import ServiceAgreementDrawerDisplay from './ServiceAgreementDrawerDisplay';
import ServiceAgreementForm from 'components/forms/ServiceAgreementForm';


const ServiceAgreementDrawerContents = (props: {
    serviceAgreement: ServiceAgreement | undefined,
    edit?: boolean,
    setEdit?: (value: boolean) => void
}) => {
    /* This redundancy forces a re-render on serviceAgreement data update. */
    const serviceAgreement = useServiceAgreement(props.serviceAgreement?.id);
    const onFinish = () => props.setEdit?.(false);

    if (!serviceAgreement) return null;

    if (props.edit) {
        return (<ServiceAgreementForm serviceAgreement={serviceAgreement} onSave={onFinish} onCancel={onFinish} />);
    }

    return <ServiceAgreementDrawerDisplay serviceAgreement={serviceAgreement} />;
}

export default ServiceAgreementDrawerContents;
