import React from 'react';
import {Link, useParams} from 'react-router-dom';

import {Card, Descriptions, DescriptionsProps} from 'antd';

import type {Zone, Template} from 'types';
import {map} from 'utils';
import Object from 'components/Object';
import MainContent from 'components/layout/MainContent';

import {useZones} from 'hooks/useZones';
import DeleteZoneButton from './DeleteZoneButton';
import {useTemplate} from 'hooks/useTemplates';
import MarkdownDiv from 'components/MarkdownDiv';

function items(zone: Zone, parentZone: Zone|undefined, template: Template) {
    const items: DescriptionsProps['items'] = [
        {
            key: 'name',
            label: 'Name',
            children: zone.name,
            span: 4
        },
        {
            key: 'template',
            label: 'Template',
            children: <Link to={`/settings/templates/${template.id}`}>{template.name}</Link>,
            span: 4
        }
    ];
    if (parentZone) {
        items.push({
            key: 'parent',
            label: 'Parent Zone',
            children: <Link to={`/zones/${parentZone.id}`}>{parentZone.name}</Link>,
            span: 4
        })
    }

    return items;
}


const ZoneDetailPage = () => {
    let { zoneId } = useParams();

    const zones = map(useZones());
    const zone = zoneId ? zones[zoneId] : undefined;

    const template = useTemplate(zone?.template.id, 'zone');

    if (!zone || !template) {
        return null;
    }

    const parentZone = zone.parentZone?.id ? zones[zone.parentZone.id] : undefined;

    return (
        <MainContent>
            <Card>
                <Descriptions
                    title="Zone Info"
                    items={items(zone, parentZone, template)}
                    column={4}
                />
                <MarkdownDiv markdown={zone.boundary} />
            </Card>
            <DeleteZoneButton zoneId={zone.id} />
            <Object object={zone}/>
        </MainContent>
    );
}

export default ZoneDetailPage;
