import React, {useState} from 'react';
import {useParams} from 'react-router-dom';

import {Card, Col, Row, Typography} from 'antd';

import {BaseObject} from 'types';
import Object from 'components/Object';
import MainContent from 'components/layout/MainContent';
import MarkdownDiv from 'components/MarkdownDiv';
import ObjectDrawer from 'components/drawers/ObjectDrawer';

import {useTeam} from 'hooks/useTeams';

import DeleteTeamButton from './DeleteTeamButton';
import TeamDetailCard from './cards/TeamDetailCard';
import TeamGraph from './graph/TeamGraph';

import styles from './TeamDetailPage.module.scss';
import {useRoles} from '../../hooks/useRoles';

const TeamDetailPage = () => {
    useRoles();
    const [selected, setSelected] = useState<BaseObject>()
    let { teamId } = useParams();

    const team = useTeam(teamId);

    if (!team) {
        return null;
    }

    function onClick(obj: BaseObject) {
        setSelected(obj);
    }

    return (
        <MainContent>
            <Row gutter={16} className={styles.TeamRow}>
                <Col xs={24} md={12}>
                    <Card className={styles.TeamChart}>
                        <div className="card-header">
                            <Typography.Title level={2}>{team.name}</Typography.Title>
                        </div>
                        <div className="card-body">
                            <MarkdownDiv markdown={team.purpose} />
                            <TeamGraph team={team} onClick={onClick}/>
                        </div>
                    </Card>
                </Col>
                <Col xs={24} md={12}>
                    <Card className={styles.TeamDetails}>
                        <TeamDetailCard team={team} onClick={onClick}/>
                    </Card>
                </Col>
            </Row>

            <DeleteTeamButton teamId={team.id} />
            <Object object={team}/>

            <ObjectDrawer obj={selected} open={!!selected} onClose={() => setSelected(undefined)} />
        </MainContent>
    );
}

export default TeamDetailPage;

/*
<Col xs={24} md={24}>
    <Card className={styles.MeetingCard}>
        <div className='card-header'>
            <Flex align='center'>
                <Typography.Title level={2}>{team.name}</Typography.Title>
                <button className='ant-btn css-dev-only-do-not-override-xmsdt3 ant-btn-default ant-btn-color-default ant-btn-variant-outlined'>Meeting Setup</button>
            </Flex>
        </div>
        <div className='card-body'>
            <Row>
                <Col xs={24} md={12}></Col>
                <Col xs={24} md={12} className={styles.MeetingDetail}>
                    <h4>ARS IT Advisor</h4>
                    <label>Decision</label>
                    <p className='text-primary'>Paul Gauguin</p>
                    <label>Description</label>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s </p>
                    <label>Note</label>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s </p>
                </Col>
            </Row>
        </div>
    </Card>
</Col>
*/
