import React, {CSSProperties} from 'react';
import {GraphNode} from './types';

function backgroundColor(node: GraphNode, themeMode: string) {
    if (themeMode === 'dark' || node.obj.type === 'role') {
        return 'transparent';
    }
    return '#000000B0';
}

const TextLabel = (
    {x, y, r, children, themeMode, show, ...props}: React.SVGAttributes<SVGForeignObjectElement> & {
        themeMode: string,
        show?: boolean
        node: GraphNode
    }
) => {
    r = r ? r as number : 0;
    const style = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none',
        touchAction: 'none',
        height: '100%',
        fontWeight: 'bold',
        padding: '8px'
    } as CSSProperties;

    const textStyle = {
        textAlign: 'center',
        color: 'white',
        backgroundColor: backgroundColor(props.node, themeMode),
        padding: '0 4px',
        borderRadius: '2px'
    } as CSSProperties;

    if (r < 16) {
        return null;
    }

    return (
        <foreignObject
            {...props}
            x={x}
            y={y}
            width={r * 2}
            height={r * 2}
            style={{
                pointerEvents: 'none',
                touchAction: 'none',
                visibility: show ? 'visible' : 'hidden',
            }}
        >
            <div style={style}>
                <span style={textStyle}>{props.node.obj.name}</span>
            </div>
        </foreignObject>
    );
};

export default TextLabel;
