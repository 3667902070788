import {Team} from 'types';
import CreateGoalSteps from 'components/steps/goals/CreateGoalSteps';
import CreateButton from 'components/steps/_common/CreateButton';

const AddGoalButton = (props: {
    team: Team
}) => {
    return (
        <CreateButton
            title={'Add Goal'}
        >
            {(onSuccess, onCancel) => (
                <CreateGoalSteps team={props.team} onSuccess={onSuccess} onCancel={onCancel} />
            )}
        </CreateButton>
    );
}

export default AddGoalButton;
