import React from 'react';
import {useParams} from 'react-router-dom';
import {Card, Descriptions} from 'antd';

import type {Goal, Template} from 'types';
import {map} from 'utils';
import {useTemplates} from 'hooks/useTemplates';
import {useGoal} from 'hooks/useGoals';
import Object from 'components/Object';
import MainContent from 'components/layout/MainContent';
import DeleteGoalButton from './DeleteGoalButton';


function items(goal: Goal, templates: Record<string, Template>) {
    return [
        {
            key: 'name',
            label: 'Name',
            children: goal.name,
            span: 3
        },
        {
            key: 'description',
            label: 'Description',
            children: goal.description
        }
    ]
}


const GoalDetailPage = () => {
    const {goalId} = useParams();
    const goal = useGoal(goalId);
    const templates = map<Template>(useTemplates());

    if (!goal) {
        return null;
    }
    return (
        <MainContent>
            <Card>
                <Descriptions
                    title="Goal Info"
                    items={items(goal, templates)}
                />
            </Card>
            <Object object={goal} />
            <DeleteGoalButton goalId={goal.id} />
        </MainContent>
    );
}

export default GoalDetailPage;
