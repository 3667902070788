import {App, Card, Row, Col} from 'antd';

import {useCurrentUser} from 'hooks/useCurrentUser';

import styles from './HomePage.module.scss';

import CreateButton from 'components/steps/_common/CreateButton';
import CreateZoneSteps from 'components/steps/zones/CreateZoneSteps';
import CreateTeamSteps from 'components/steps/teams/CreateTeamSteps';
import CreateRoleSteps from 'components/steps/roles/CreateRoleSteps';

import createRole from 'assets/create_role.svg';
import createTeam from 'assets/create_team.svg';
import createZone from 'assets/create_zone.svg';

const HomePage = () => {
    const {message} = App.useApp();

    const currentUser = useCurrentUser();
    if (!currentUser) {
        return null;
    }

    async function onCreateZone() {
        return message.success('Your zone was created successfully.');
    }

    // FIXME: add permissions.
    return (
        <>
        <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                <Card className={styles.createCard}>
                    <div className='cc-icon'>
                        <img src={createRole} alt="" />
                    </div>
                    <CreateButton
                        title={'Create a zone'}
                        onSuccess={onCreateZone}
                    >
                        {(onSuccess, onCancel) => (
                            <CreateZoneSteps onSuccess={onSuccess} onCancel={onCancel} />
                        )}
                    </CreateButton>
                    <span className='cc-link'></span>
                </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                <Card className={styles.createCard}>
                    <div className='cc-icon'>
                        <img src={createTeam} alt="" />
                    </div>
                    <CreateButton
                        title={'Create a team'}
                    >
                        {(onSuccess, onCancel) => (
                            <CreateTeamSteps onSuccess={onSuccess} onCancel={onCancel} />
                        )}
                    </CreateButton>
                    <span className='cc-link'></span>
                </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                <Card className={styles.createCard}>
                    <div className='cc-icon'>
                        <img src={createZone} alt="" />
                    </div>
                    <CreateButton
                        title={'Create a role'}
                    >
                        {(onSuccess, onCancel) => (
                            <CreateRoleSteps onSuccess={onSuccess} onCancel={onCancel} />
                        )}
                    </CreateButton>
                    <span className='cc-link'></span>
                </Card>
            </Col>
        </Row>
        </>
    );
}

export default HomePage;
