import React, {forwardRef, RefObject} from 'react';
import {useSpring, animated} from '@react-spring/web';

interface AnimatedImageProps extends React.SVGAttributes<SVGImageElement> {
    href: string|undefined
}

const AnimatedImage = forwardRef((
    {x, y, width, height, ...props}: AnimatedImageProps, ref
) => {
    const animatedProps = useSpring({x, y, width, height, config: { duration: 200 }});
    return (
        <animated.image
            {...props}
            x={animatedProps.x}
            y={animatedProps.y}
            height={animatedProps.height}
            width={animatedProps.width}
            clipPath="inset(0% round 50%)"
            ref={ref as RefObject<SVGImageElement>}
        />
    );
});

export default AnimatedImage;
