import {Outlet} from 'react-router-dom';
import {Layout} from 'antd';
import type {BreadcrumbItemType, BreadcrumbSeparatorType} from 'antd/es/breadcrumb/Breadcrumb';
import {kebabCase} from 'change-case';
import classNames from 'classnames';

import MainSider from 'components/sider/MainSider';
import MainHeader from 'components/header/MainHeader';

import styles from './MainLayout.module.scss';


const MainLayout = (props: {
    pageTitle: string|undefined,
    breadcrumbs:  Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] | null | undefined,
}) => {
    const className = classNames(styles.wrapper, {
        [kebabCase(props.pageTitle || '')]: !!props.pageTitle
    });

    return (
        <Layout className={className}>
            <MainSider/>
            <Layout className='gg-main'>
                <MainHeader pageTitle={props.pageTitle} breadcrumbs={props.breadcrumbs} />
                <Layout.Content className='gg-content'>
                    <Outlet/>
                </Layout.Content>
            </Layout>
        </Layout>
    );
}

export default MainLayout;
