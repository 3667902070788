import {Tabs, TabsProps} from 'antd';

import {BaseObject, Team} from 'types';
import RolesTab from './RolesTab';
import GoalsTab from './GoalsTab';
import DecisionsTab from './DecisionsTab';
import AgreementsTab from './AgreementsTab';

const TeamDetailCard = (props: {
    team: Team
    onClick?: (obj: BaseObject) => void
}) => {
    const items: TabsProps['items'] = [
        {
            key: 'roles',
            label: 'Roles',
            children: <RolesTab team={props.team} onClick={props.onClick} />,
        },
        {
            key: 'goals',
            label: 'Goals',
            children: <GoalsTab team={props.team} onClick={props.onClick} />,
        },
        {
            key: 'decisions',
            label: 'Decisions',
            children: <DecisionsTab team={props.team} onClick={props.onClick} />,
        },
        {
            key: 'agreements',
            label: 'Agreements',
            children: <AgreementsTab team={props.team} onClick={props.onClick} />,
        },
    ];

    return (
        <Tabs defaultActiveKey="roles" items={items} />
    );
}

export default TeamDetailCard;
