import {App, Form, Input, Button, Typography} from 'antd';
import {LockOutlined, MailOutlined} from '@ant-design/icons';

import type {Organization, Person} from 'types';
import {getErrorMessage} from 'utils';

import {useThemeMode} from 'hooks/useThemeMode';

import logo from 'assets/logo-light.png';
import logoDark from 'assets/logo-dark.png';

type LoginFormRequest = {
    username: string,
    password: string,
}

export type LoginFormResponse = {
    user: Person,
    organizations: Organization[] | null,
    token: {
        access_token: string,
        token_type: 'bearer'
    }
}

const LoginForm = (props: {
    onLogin?: (data: LoginFormResponse) => void

}) => {
    const {message} = App.useApp();
    const themeMode = useThemeMode();
    const src = themeMode === 'light' ? logoDark : logo;

    async function onFinish(values: LoginFormRequest) {
        const formData = new FormData();
        formData.append('username', values.username);
        formData.append('password', values.password);

        try {
            const res = await fetch(
                `${process.env.REACT_APP_API_URL}/auth/login`,
                {
                    method: 'POST',
                    body: formData,
                }
            );
            if (res.status !== 200) {
                message.error('Invalid Username or Password.');
            } else {
                props.onLogin?.(await res.json() as LoginFormResponse);
            }
        } catch (e) {
            message.error(<><b>[Unknown Network Error]</b> {getErrorMessage(e)}</>);
        }
    }

    return (
        <>
        <div className='login-logo'>
            <img src={src} alt={'Viggo'} />
        </div>
        <Form
            name="login"
            className={'auth-form'}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
        >
            <Typography.Title>Login</Typography.Title>
            <Form.Item
                name="username"
                rules={[
                    {
                        required: true,
                        message: 'REQUIRED',
                    },
                ]}
            >
                <Input
                    prefix={<MailOutlined/>}
                    placeholder="Email"
                    autoComplete={'email'}
                />
            </Form.Item>
            <Form.Item
                name="password"
                className="login-pass"
                rules={[
                    {
                        required: true,
                        message: 'REQUIRED',
                    },
                ]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon"/>}
                    type="password"
                    placeholder="Password"
                    autoComplete={'current-password'}
                />
            </Form.Item>
            <Form.Item>
                <a className='login-forgot-pass' href="/forgot-password">
                Forgot password?
                </a>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button">
                    Login
                </Button>
            </Form.Item>
        </Form>
        </>
    );
}

export default LoginForm;

/*
<Form.Item name="remember" valuePropName="checked" noStyle>
    <Checkbox>Remember me</Checkbox>
</Form.Item>
 */
