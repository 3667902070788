import {useNavigate} from 'react-router-dom';
import {Button, Flex, App, Modal, theme} from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import type {PlatformDispatch, PlatformError, Team} from 'types';
import {personIsAdmin} from 'utils';
import {useCurrentUser} from 'hooks/useCurrentUser';

import {AppDispatch, useAppDispatch} from 'store';
import {deleteTeam} from 'features/teamSlice';
import {useTeam} from 'hooks/useTeams';

const DeleteTeamButton = (props: {
    teamId: string
}) => {
    const team = useTeam(props.teamId);
    const dispatch: AppDispatch = useAppDispatch();
    const {message} = App.useApp();

    const isAdmin = personIsAdmin(useCurrentUser());
    const {token: {colorError}} = theme.useToken();
    const navigate = useNavigate();

    if (!isAdmin) {
        return null;
    }

    async function onConfirm() {
        const res = await dispatch(deleteTeam(props.teamId) as any) as PlatformDispatch<Team>;
        if (res.error) {
            return message.error((res.payload as PlatformError).message);
        }
        message.success('Your team was successfully deleted.');
        navigate('/teams', {replace:true});
    }

    return (
        <Flex>
            <Button
                danger
                onClick={() => {
                    Modal.confirm({
                        title: `Confirm deletion of team '${team?.name}'`,
                        content: 'Are you sure you want to permanently delete this team?  This operation cannot be undone.',
                        footer: (_, {OkBtn, CancelBtn}) => (
                            <>
                                <CancelBtn/>
                                <OkBtn />
                            </>
                        ),
                        okText: 'Delete Team',
                        okButtonProps: {type:'default', danger:true},
                        icon: <CloseCircleOutlined style={{color:colorError}}/>,
                        onOk: onConfirm
                    });
                }}
            >
                Delete Team
            </Button>
        </Flex>
    )
}

export default DeleteTeamButton;
