import type {BaseObject, ObjectRef, OneToManyField} from 'types';
import {titleCase} from 'utils';
import {useObjects} from 'hooks/useObjects';
import ObjectTypeIcon from 'components/ObjectTypeIcon';
import DrawerDescription from './DrawerDescription';

const FieldItem = (props: {
    objectRef: ObjectRef
    objects: BaseObject[]
}) => {
    const value = props.objects.find(obj => obj.id === props.objectRef.id);
    if (!value) {
        return <div>{titleCase(props.objectRef.type)}[{props.objectRef.id}] Not Found! Please report this error.</div>
    }

    return <div><ObjectTypeIcon style={{marginRight: '.25em'}} type={props.objectRef.type}/> {value.name}</div>
}

const OneToManyFieldDrawerDescription = (props: {
    obj: BaseObject & { [p: string]: any }
    field: OneToManyField
}) => {
    const objects = useObjects(props.field.allowed);

    const refs = props.obj[props.field.name] as ObjectRef[] | undefined;
    if (!refs) {
        return null;
    }

    return (
        <DrawerDescription title={props.field.name}>
            {refs.map((objectRef, i) => {
                return <FieldItem
                    key={`${i}`}//key={`${ref.type}-${ref.id}-${i}`}
                    objectRef={objectRef}
                    objects={objects[objectRef.type] || []}
                />
            })}
        </DrawerDescription>
    );
}

export default OneToManyFieldDrawerDescription;
