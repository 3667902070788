import React, {useState} from 'react';
import {Popover} from 'antd';
import {TeamGraphNode} from './types';
import {BaseObject} from '../../../types';

function stroke(node: TeamGraphNode, themeMode?: 'light'|'dark') {
    if (themeMode === 'dark') {
        return node.obj.type === 'team' ? '#01bd50' : '#0011c8';
    }
    return node.obj.type === 'team' ? '#01bd50' : '#0011c8';
}

function fill(node: TeamGraphNode, themeMode?: 'light'|'dark') {
    if (themeMode === 'dark') {
        return node.obj.type === 'team' ? '#8bd998' : '#5985e5';
    }
    return node.obj.type === 'team' ? '#f5fbf7' : '#f2f3fd';
}

const NetworkGraphNode = (props: {
    themeMode?: 'light'|'dark',
    node: TeamGraphNode,
    r: number,
    onClick?: (obj: BaseObject) => void
}) => {
    const [hover, setHover] = useState(false);
    const radius = props.node.obj.type === 'team' ? 2*props.r : props.r;

    return (
        <Popover open={hover} content={props.node.obj.name}>
            <circle
                style={{cursor: props.onClick ? 'pointer' : 'default'}}
                cx={props.node.x} cy={props.node.y} r={radius}
                stroke={stroke(props.node, props.themeMode)}
                fill={fill(props.node, props.themeMode)}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={() => props.onClick?.(props.node.obj)}
            />
        </Popover>
    );
}

export default NetworkGraphNode;
