import type {Person} from 'types';
import {Avatar, Flex} from 'antd';
import React from 'react';

const RolePersonDetail = (props: {
    person: Person|undefined
}) => {
    if (!props.person) {
        return <div className='no-role' >This role is unfilled.</div>
    }

    return (
        <div>
            <Flex className='drawer-avatar' vertical>
                {props.person.picture_large ? <Avatar className='avatar-media' src={props.person.picture_large} alt={props.person.name} /> : null}
                <span className='avatar-name'>{props.person.name}</span>
            </Flex>
        </div>
    );
}

export default RolePersonDetail;
