import React, {useState} from 'react';
import {App, Button, Form, FormProps, Input} from 'antd';
import {type ErrorDetail, type ErrorDetailDispatch, type Zone} from 'types';
import {AppDispatch, useAppDispatch} from 'store';
import {createZone, updateZone} from 'features/zoneSlice';
import {useTemplate} from 'hooks/useTemplates';
import FieldFormItem from './common/FieldFormItem';

const ZoneForm = (props: {
    zone?: Zone,
    onSave?: (zone: Zone) => void,
    onCancel?: () => void;
}) => {
    const [zone, setZone] = useState<Zone & {[p: string]: any}>(props.zone ? props.zone : {} as Zone);
    const template = useTemplate(props.zone?.template.id, props.zone?.type);
    const templateFields = template ? template.fields : [];

    const dispatch: AppDispatch = useAppDispatch();
    const {message} = App.useApp();

    async function onCreate() {
        const res = await dispatch(createZone(zone));
        if ((res as ErrorDetailDispatch<Zone>).error) {
            return message.error((res.payload as ErrorDetail).detail.toString());
        }
        props.onSave?.(zone);
        return message.success('Zone created successfully.');
    }

    async function onUpdate() {
        const res = await dispatch(updateZone(zone));
        if ((res as ErrorDetailDispatch<Zone>).error) {
            return message.error((res.payload as ErrorDetail).detail.toString());
        }
        props.onSave?.(zone);
        return message.success('Zone updated successfully.');
    }

    const onFinish: FormProps<Zone>['onFinish'] = async () => {
        return props.zone ? onUpdate() : onCreate();
    };

    if (props.zone === undefined) {
        return null;
    }

    return (
        <Form
            layout={'vertical'}
            onFinish={onFinish}
        >
            <Form.Item<Zone>
                label="Name"
                name={'name'}
                rules={[{required: true, message: "Please enter a value."}]}
                initialValue={props.zone.name}
            >
                <Input onChange={event => {
                    setZone({...zone, name: event.target.value})
                }} />
            </Form.Item>
            <Form.Item<Zone>
                label="Boundary"
                name={'boundary'}
                rules={[{required: true, message: "Please enter a value."}]}
                help={'Enter a short-form boundary. Markdown is accepted but please no bullets.'}
                initialValue={props.zone.boundary}
            >
                <Input.TextArea onChange={event => {
                    setZone({...zone, boundary: event.target.value})
                }} />
            </Form.Item>

            {templateFields.map((field, i) => (
                <FieldFormItem
                    key={`field-${i}`}
                    field={field}
                    obj={zone}
                    onChange={value => {
                        const newZone = {...zone, [field.name]: value}
                        setZone(newZone);
                    }}/>
            ))}

            <Form.Item style={{marginTop: '36px'}}>
                <Button type="primary" htmlType={'submit'}>Save</Button>
            </Form.Item>
        </Form>
    );
}

export default ZoneForm;
