import {useNavigate} from 'react-router-dom';
import {Button, Flex, App, Modal, theme} from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import type {Agreement, PlatformDispatch, PlatformError} from 'types';
import {personIsAdmin} from 'utils';
import {useCurrentUser} from 'hooks/useCurrentUser';
import {useZone} from 'hooks/useZones';

import {AppDispatch, useAppDispatch} from 'store';
import {deleteZone} from 'features/zoneSlice';

const DeleteZoneButton = (props: {
    zoneId: string
}) => {
    const zone = useZone(props.zoneId);
    const dispatch: AppDispatch = useAppDispatch();
    const {message} = App.useApp();

    const isAdmin = personIsAdmin(useCurrentUser());
    const {token: {colorError}} = theme.useToken();
    const navigate = useNavigate();

    if (!isAdmin) {
        return null;
    }

    async function onConfirm() {
        const res = await dispatch(deleteZone(props.zoneId) as any) as PlatformDispatch<Agreement>;
        if (res.error) {
            return message.error((res.payload as PlatformError).message);
        }
        message.success('Your zone was successfully deleted.');
        navigate('/zones', {replace:true});
    }

    return (
        <Flex>
            <Button
                danger
                onClick={() => {
                    Modal.confirm({
                        title: `Confirm deletion of zone '${zone?.name}'`,
                        content: 'Are you sure you want to permanently delete this zone?  This operation cannot be undone.',
                        footer: (_, {OkBtn, CancelBtn}) => (
                            <>
                                <CancelBtn/>
                                <OkBtn />
                            </>
                        ),
                        okText: 'Delete Zone',
                        okButtonProps: {type:'default', danger:true},
                        icon: <CloseCircleOutlined style={{color:colorError}}/>,
                        onOk: onConfirm
                    });
                }}
            >
                Delete Zone
            </Button>
        </Flex>
    )
}

export default DeleteZoneButton;
