import {Form, Input} from 'antd';

const NameStep = (props: {
    title: string,
    name: string,
    setName: (name: string) => void,
}) => {
    const [form] = Form.useForm<{ name: string }>();
    Form.useWatch(values => {
        if (values.name !== undefined) {
            props.setName(values.name);
        }
    }, form);

    return (
        <Form
            name="nameStep"
            form={form}
            style={{maxWidth: 600}}
            initialValues={{name: props.name}}
            layout="vertical"
            autoComplete="off"
            className='formControl'
        >
            <Form.Item<{name: string}>
                label={props.title}
                name="name"

                rules={[{required: true, message: "Please enter a descriptive name."}]}
            >
                <Input/>
            </Form.Item>
        </Form>)
}

export default NameStep;