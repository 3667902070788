import {Form, Input} from 'antd';
import {MailOutlined} from '@ant-design/icons';

const EmailFormItem = (props: {
    extra?: string
}) => {
    return (
        <Form.Item
            name="email"
            rules={[
                {
                    required: true,
                    message: 'REQUIRED',
                },
            ]}
            extra={props.extra}
        >
            <Input
                prefix={<MailOutlined/>}
                placeholder="Email"
                autoComplete={'email'}
            />
        </Form.Item>
    );
}

export default EmailFormItem;
