import {Form} from 'antd';
import {ObjectRef, OneToManyField} from 'types';

import OneToManyFormItem from 'components/forms/common/OneToManyFormItem';

function initialValues(refs: ObjectRef[]|undefined) {
    if (refs) {
        return refs.map(ref => JSON.stringify(ref))
    }
    return []
}

const OneToManyFieldStep = (props: {
    field: OneToManyField
    objectRefs: ObjectRef[]|undefined,
    setObjectRefs: (values: ObjectRef[]) => void,
}) => {
    const [form] = Form.useForm<{ values: string[] }>();
    Form.useWatch(values => {
        if (values.values !== undefined) {
            props.setObjectRefs(values.values.map(value => JSON.parse(value)));
        }
    }, form);

    return (
        <Form
            name="oneToManyFieldStep"
            form={form}
            style={{maxWidth: 600}}
            initialValues={{values: initialValues(props.objectRefs)}}
            layout="vertical"
            autoComplete="off"
        >
            <OneToManyFormItem {...props} />
        </Form>)
}

export default OneToManyFieldStep;
