import {TeamGraphNode} from './types';

function stroke(themeMode?: 'light'|'dark') {
    return themeMode === 'dark' ? 'white' : 'black';
}

const NetworkGraphLink = (props: {
    themeMode?: 'light'|'dark',
    source: TeamGraphNode,
    target: TeamGraphNode,
}) => {
    return (
        <line
            x1={props.source.x}
            x2={props.target.x}
            y1={props.source.y}
            y2={props.target.y}
            style={{stroke:stroke(props.themeMode), strokeWidth: 1}}
        />
    );
}

export default NetworkGraphLink;
