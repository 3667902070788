import type {Team} from 'types';

import {useTeam} from 'hooks/useTeams';
import TeamDrawerDisplay from './TeamDrawerDisplay';
import TeamForm from 'components/forms/TeamForm';


const TeamDrawerContents = (props: {
    team: Team | undefined,
    edit?: boolean,
    setEdit?: (value: boolean) => void
}) => {
    /* This redundancy forces a re-render on team data update. */
    const team = useTeam(props.team?.id);
    const onFinish = () => props.setEdit?.(false);

    if (!team) return null;

    if (props.edit) {
        return (<TeamForm team={team} onSave={onFinish} onCancel={onFinish} />);
    }

    return <TeamDrawerDisplay team={team} />;
}

export default TeamDrawerContents;
