import {Team} from 'types';
import CreateRoleSteps from 'components/steps/roles/CreateRoleSteps';
import CreateButton from 'components/steps/_common/CreateButton';

const AddRoleButton = (props: {
    team: Team
}) => {
    return (
        <CreateButton
            title={'Add Role'}
        >
            {(onSuccess, onCancel) => (
                <CreateRoleSteps team={props.team} onSuccess={onSuccess} onCancel={onCancel} />
            )}
        </CreateButton>
    );
}

export default AddRoleButton;
