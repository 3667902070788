import {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from 'store';

import {Goal, Team} from 'types';
import {nameSort} from 'utils';
import {fetchGoals, selectGoals, selectGoalStatus} from 'features/goalSlice';

export function useGoals(): Goal[] {
    const dispatch = useAppDispatch();
    const goals = useAppSelector(selectGoals);
    const goalStatus = useAppSelector(selectGoalStatus);

    useEffect(() => {
        if (goalStatus === 'idle') {
            dispatch(fetchGoals() as any);
        }
    }, [goalStatus, dispatch]);

    return [...goals].sort(nameSort);
}

export function useGoal(goalId: string|undefined) {
    const goals = useGoals();
    return goalId ? goals.find(Goal => Goal.id === goalId) : null;
}

export function useTeamGoals(team: Team|null): Goal[] {
    const goals = useGoals();
    return team ? goals.filter(goal => goal.team.id === team.id) : [];
}
