import {useElementSize} from '@custom-react-hooks/use-element-size';

import type {Team, Role, Person, BaseObject} from 'types';
import {map} from 'utils'

import styles from './TeamGraph.module.scss';
import {useTeamGraphData} from 'hooks/useData';
import Loading from 'components/Loading';
import NetworkGraph from './NetworkGraph';
import {TeamGraphLink, TeamGraphNode} from './types';

function networkData(team: Team, roles: Role[] | undefined, people: Record<string, Person>) {
    const nodes: Record<string, TeamGraphNode> = {};
    nodes[team.id] = {obj: team};

    const links = [] as TeamGraphLink[];

    if (roles) {
        for (const role of roles) {
            const user = role.assignment?.id ? people[role.assignment.id] : undefined;
            nodes[role.id] = {obj: role, user};
        }

        for (const role of roles) {
            links.push({
                source: nodes[role.id],
                target: nodes[team.id],
            });
        }
    }

    return {
        nodes: Object.keys(nodes).map(key => nodes[key]),
        links
    };
}

const TeamGraph = (props: {
    team: Team
    onClick?: (obj: BaseObject) => void,
}) => {
    const [setRef, size] = useElementSize();
    const objs = useTeamGraphData(props.team);
    const data = networkData(props.team, objs?.roles, map(objs?.people))

    if (!objs) {
        return (<Loading/>);
    }

    return (
        <div ref={setRef} className={styles.container}>
            {size.width && size.height ?
                <NetworkGraph width={size.width} height={size.height} data={data} onClick={props.onClick} /> :
                null
            }
        </div>
    );
}

export default TeamGraph;
