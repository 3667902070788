import React, {forwardRef, RefObject} from 'react';
import {useSpring, animated} from '@react-spring/web';

interface AnimatedCircleProps extends React.SVGAttributes<SVGCircleElement> {
    cx: number
}

const AnimatedCircle = forwardRef((
    {cx, cy, r, ...props}: AnimatedCircleProps, ref
) => {
    const {fill='#63bab0', fillOpacity=.05, stroke='#63bab0'} = props;

    const animatedProps = useSpring({cx, cy, r, config: { duration: 200 }});
    return (
        <animated.circle
            {...props}
            fill={fill}
            fillOpacity={fillOpacity}
            stroke={stroke}
            r={animatedProps.r}
            cx={animatedProps.cx}
            cy={animatedProps.cy}
            ref={ref as RefObject<SVGCircleElement>}
        />
    );
});

export default AnimatedCircle;
