import type {Organization, Person} from 'types';
import apiClient from 'api';

/* This is the main page loader. */
import {setCurrentUser} from 'features/currentUserSlice';
import {setOrg} from 'features/orgSlice';
import store from './store';

type Me = {
    person: Person
    organizations: Organization[]|null,
    token: string,
}

export async function current() {
    const url = '/auth/me';

    return apiClient
        .get<Me>(url)
        .then(me => {
            store.dispatch(setCurrentUser(me.person));
            if (me.organizations) {
                if (me.organizations.length === 1) {
                    store.dispatch(setOrg(me.organizations[0]));
                }
            }
            return me;
        });
}
