import {Form, Input} from 'antd';

const BoundaryStep = (props: {
    boundary: string,
    setBoundary: (boundary: string) => void,
}) => {
    const [form] = Form.useForm<{ boundary: string }>();
    Form.useWatch(values => {
        if (values.boundary !== undefined) {
            props.setBoundary(values.boundary);
        }
    }, form);

    return (
        <Form
            name="boundaryStep"
            form={form}
            style={{maxWidth: 600}}
            initialValues={{boundary: props.boundary}}
            layout="vertical"
            autoComplete="off"
            className='formControl'
        >
            <Form.Item<{boundary: string}>
                label="What is the boundary for this zone?"
                name="boundary"
                help={'Enter a short-form boundary. Markdown is accepted but please no bullets.'}

                rules={[{
                    required: true,
                    message: "Enter a short-form boundary. Markdown is accepted but please no bullets."
                }]}
            >
                <Input.TextArea/>
            </Form.Item>
        </Form>)
}

export default BoundaryStep;
