import {Form, Select} from 'antd';
import type {ObjectRef, OneToManyField} from 'types';
import {buildObjectOptions} from 'utils';
import {useObjects} from 'hooks/useObjects';

const OneToManyFormItem = (props: {
    field: OneToManyField,
    objectRefs: ObjectRef[]|undefined,
    setObjectRefs: (refs: ObjectRef[]) => void;
}) => {
    const objs = useObjects(props.field.allowed);

    const options = buildObjectOptions(objs);
    const values = props.objectRefs ? props.objectRefs.map(objectRef => (JSON.stringify({id:objectRef.id, type:objectRef.type}))) : undefined;

    return (
        <Form.Item
            label={props.field.name}
            rules={[{required: props.field.required, message: 'Select one or more values.'}]}
            name={props.field.name}
            initialValue={values}
        >
            <Select
                mode={'multiple'}
                options={options}
                onChange={values => {
                    props.setObjectRefs(values.map((value: string) => JSON.parse(value)))
                }}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
            />
        </Form.Item>
    );
}

export default OneToManyFormItem;
