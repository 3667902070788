import {Card} from 'antd';
import MarkdownDiv from './MarkdownDiv';

const MarkdownCard = (props: {
    markdown: string;
}) => {
    return (
        <Card>
            <MarkdownDiv markdown={props.markdown} />
        </Card>
    );
}

export default MarkdownCard;
