import React, {useState} from 'react';
import {App, Button, Form, FormProps, Input} from 'antd';
import {type ErrorDetail, type ErrorDetailDispatch, type Decision} from 'types';
import {AppDispatch, useAppDispatch} from 'store';
import {createDecision, updateDecision} from 'features/decisionSlice';
import {useTemplate} from 'hooks/useTemplates';
import FieldFormItem from './common/FieldFormItem';

const DecisionForm = (props: {
    decision?: Decision,
    onSave?: (decision: Decision) => void,
    onCancel?: () => void;
}) => {
    const [decision, setDecision] = useState<Decision & {[p: string]: any}>(props.decision ? props.decision : {} as Decision);
    const template = useTemplate(props.decision?.template.id, props.decision?.type);
    const templateFields = template ? template.fields : [];

    const dispatch: AppDispatch = useAppDispatch();
    const {message} = App.useApp();

    async function onCreate() {
        const res = await dispatch(createDecision(decision));
        if ((res as ErrorDetailDispatch<Decision>).error) {
            return message.error((res.payload as ErrorDetail).detail.toString());
        }
        props.onSave?.(decision);
        return message.success('Decision created successfully.');
    }

    async function onUpdate() {
        const res = await dispatch(updateDecision(decision));
        if ((res as ErrorDetailDispatch<Decision>).error) {
            return message.error((res.payload as ErrorDetail).detail.toString());
        }
        props.onSave?.(decision);
        return message.success('Decision updated successfully.');
    }

    const onFinish: FormProps<Decision>['onFinish'] = async () => {
        return props.decision ? onUpdate() : onCreate();
    };

    if (props.decision === undefined) {
        return null;
    }

    return (
        <Form
            layout={'vertical'}
            onFinish={onFinish}
        >
            <Form.Item<Decision>
                label="Name"
                name={'name'}
                rules={[{required: true, message: "Please enter a value."}]}
                initialValue={props.decision.name}
            >
                <Input onChange={event => {
                    setDecision({...decision, name: event.target.value})
                }} />
            </Form.Item>
            <Form.Item<Decision>
                label="Description"
                name={'description'}
                help={'Markdown is allowed.'}
                rules={[{required: true, message: "Please enter a value."}]}
                initialValue={props.decision.description}
            >
                <Input.TextArea onChange={event => {
                    setDecision({...decision, description: event.target.value})
                }} />
            </Form.Item>

            {templateFields.map((field, i) => (
                <FieldFormItem
                    key={`field-${i}`}
                    field={field}
                    obj={decision}
                    onChange={value => {
                        const newDecision = {...decision, [field.name]: value}
                        setDecision(newDecision);
                    }}/>
            ))}

            <Form.Item style={{marginTop: '36px'}}>
                <Button type="primary" htmlType={'submit'}>Save</Button>
            </Form.Item>
        </Form>
    );
}

export default DecisionForm;
