import {TeamGraphLink, TeamGraphNode} from './types';
import * as d3 from 'd3';

import forceBoundary from 'd3-force-boundary';

function drawNetwork(props: {
    width: number,
    height: number,
    data: {nodes: TeamGraphNode[], links: TeamGraphLink[]}
    radius?: number
}) {
    const nodes = props.data.nodes.map(d => ({...d}));
    const links = props.data.links.map(d => ({...d}));
    const radius = props.radius ? props.radius : 10;

    const simulation = d3.forceSimulation<TeamGraphNode, TeamGraphLink>(nodes)
        // Force #1: links between nodes
        .force('link', d3.forceLink<TeamGraphNode, TeamGraphLink>(links)
            .id((d) => d.obj.id))

        // Force #2: avoid node overlaps
        //.force('collide', d3.forceCollide().radius(RADIUS))
        .force('collide', d3.forceCollide().radius(function() { return radius; }))

        // Force #3: attraction or repulsion between nodes
        .force('charge', d3.forceManyBody())

        // Force #4: nodes are attracted by the center of the chart area
        .force('center', d3.forceCenter(props.width / 2, props.height / 2))

        /*
        .force('x', d3.forceX(props.width / 2).strength(0.5))
        .force('y', d3.forceY(props.height / 2).strength(0.5))
         */
        .force('boundary', forceBoundary(0,0, props.width, props.height))

        .stop()

    simulation.tick(300);
    return {nodes, links};
}

export default drawNetwork;
