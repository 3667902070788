import type {Person, Role} from 'types';
import RoleForm from 'components/forms/RoleForm';

const RoleDrawerEdit = (props: {
    role: Role,
    person: Person | undefined,
    onFinish?: () => void;
}) => (
    <RoleForm role={props.role} onSave={props.onFinish} onCancel={props.onFinish}/>
);

export default RoleDrawerEdit;
