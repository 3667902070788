import React, {useEffect} from 'react';
import {Select} from 'antd';

import {selectOptions} from 'utils';
import {useTemplates} from 'hooks/useTemplates';
import type {Template} from 'types';

const TemplateStep = (props: {
    objectType: Template['objectType']
    templateId?: string,
    setTemplate: (templateId: string) => void;
}) => {
    const {templateId, setTemplate} = props;
    const templates = useTemplates(props.objectType);
    const options = selectOptions(templates);

    useEffect(() => {
        if (templates && templates.length === 1 && !templateId) {
            setTemplate(templates[0].id);
        }
    }, [templateId, setTemplate, templates]);

    return (
        <div className={'formControl'}>
            <label className={'viggo-required'}>What template do you want to use?</label>
            <Select
                placeholder="Please select"
                value={props.templateId}
                onChange={props.setTemplate}
                options={options}
            />
        </div>
    )
}

export default TemplateStep;
