import {Form, Select} from 'antd';
import {SingleSelectField} from 'types';

const SingleSelectFormItem = (props: {
    field: SingleSelectField,
    value: string|undefined,
    setValue: (value: string|null) => void,
}) => {
    const options = props.field.options.map(option => {
        return {label: option, value: option};
    });

    return (
        <Form.Item
            label={props.field.name}
            rules={[{required: props.field.required, message: 'Select a value.'}]}
            name={props.field.name}
            initialValue={props.value}
        >
            <Select
                options={options}
                onChange={value => value ? props.setValue(value) : props.setValue(null)}
                allowClear={!props.field.required}
            />
        </Form.Item>
    );
}

export default SingleSelectFormItem;
