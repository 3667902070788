import type {Zone} from 'types';

import {useZone} from 'hooks/useZones';
import ZoneDrawerDisplay from './ZoneDrawerDisplay';
import ZoneForm from 'components/forms/ZoneForm';


const ZoneDrawerContents = (props: {
    zone: Zone | undefined,
    edit?: boolean,
    setEdit?: (value: boolean) => void
}) => {
    /* This redundancy forces a re-render on zone data update. */
    const zone = useZone(props.zone?.id);
    const onFinish = () => props.setEdit?.(false);

    if (!zone) return null;

    if (props.edit) {
        return (<ZoneForm zone={zone} onSave={onFinish} onCancel={onFinish} />);
    }

    return <ZoneDrawerDisplay zone={zone} />;
}

export default ZoneDrawerContents;
