import React from 'react';

const DrawerDescription = (props: {
    title: string,
    children: React.ReactNode|React.ReactNode[]
}) => {
    return (
        <div className={'dd'}>
            <h3>{props.title}</h3>
            <div>{props.children}</div>
        </div>
    );
}

export default DrawerDescription;
