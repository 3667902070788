import React, {useCallback, useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import {Dropdown, MenuProps} from 'antd';

import styles from './GraphComponent.module.scss';

import type {GraphNode} from './types';
import {useThemeMode} from 'hooks/useThemeMode'

import TeamNode from './TeamNode';
import RoleNode from './RoleNode';
import Legend from './Legend';
import ZoneNode from './ZoneNode';
import NodeActions from './NodeActions';
import TextLabel from './TextLabel';


function findNodeById(root: d3.HierarchyCircularNode<GraphNode>, id: string): d3.HierarchyCircularNode<GraphNode>|null {
    if (root.data.obj.id === id) {
        return root;
    }
    if (root.children) {
        for (const child of root.children) {
            const node = findNodeById(child, id);
            if (node) {
                return node;
            }
        }
    }
    return null;
}

const GraphComponent = (props: {
    data: GraphNode,
    width: number,
    height: number,
    onSelect?: (node: GraphNode|undefined) => void;
    onAction?: (action: string, node: GraphNode) => void;
    onZoom?: () => void;
}) => {
    const themeMode = useThemeMode();
    const ref = useRef<SVGSVGElement>(null);
    const [zoomView, setZoomView] = useState<d3.ZoomView>([0, 0, 0]);
    const [focus, setFocus] = useState<d3.HierarchyCircularNode<GraphNode>>()
    const [root, setRoot] = useState<d3.HierarchyCircularNode<GraphNode>>()
    const {onZoom} = props;

    const zoom = useCallback((node: d3.HierarchyCircularNode<GraphNode>) => {
        setFocus(node);
        setZoomView([node.x, node.y, node.r * 2]);
        onZoom?.();
    }, [onZoom]);

    useEffect(() => {
        const hierarchy = d3.hierarchy<GraphNode>(props.data)
            .sum(d => {
                return d.value || 0
            })
            .sort((a, b) => {
                return (b.value || 0) - (a.value || 0);
            });

        // Compute the layout.
        const pack = (_: GraphNode) => d3.pack<GraphNode>()
            .size([props.width, props.height])
            .padding(8)
            (hierarchy);

        let root = pack(props.data);
        setRoot(root);

        if (window.location.hash && window.location.hash.length > 1) {
            const node = findNodeById(root, window.location.hash.substring(1));
            root = node ? node : root;
        }

        zoom(root);
    }, [props.width, props.height, props.data, zoom]);

    if (!root || !focus) {
        return null;
    }

    function onClick(node: d3.HierarchyCircularNode<GraphNode>) {
        if (node.data.obj.type !== 'role') {
            zoom(node);
        }
        window.location.hash = node.data.obj.id;
    }

    function onDoubleClick(node: d3.HierarchyCircularNode<GraphNode>) {
        props.onSelect?.(node.data);
    }

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        e.domEvent.stopPropagation();
        props.onAction?.(e.key, focus.data);
    };

    const k = Math.min(props.width, props.height) / zoomView[2];

    let radius = 0;
    for (const node of focus.descendants()) {
        if (node.r && (radius === 0 || node.r * k < radius)) {
            radius = node.r * k;
        }
    }

    const items = NodeActions.getByType(focus.data.obj.type) || [];
    return (
        <div className={styles.graph}>
            <Dropdown menu={{items, onClick: handleMenuClick}} trigger={['contextMenu']}>
                <svg
                    viewBox={`-${props.width / 2} -${props.height / 2} ${props.width} ${props.height}`}
                    width={props.width}
                    height={props.height}

                    style={{height: 'auto', overflow: 'visible'}}
                    ref={ref}
                    onClick={event => {
                        event.preventDefault();
                        onClick(focus?.parent ? focus.parent : root);
                    }}
                >
                    <filter x="0" y="0" width="1" height="1" id="solid">
                        <feFlood floodColor="white" floodOpacity={.5}></feFlood>
                        <feComposite in="SourceGraphic"></feComposite>
                    </filter>

                    <g>
                        {root
                            .descendants()
                            .slice(1)
                            .filter(node => node.data.obj.type === 'zone')
                            .map((node, index) => (
                                <ZoneNode
                                    key={`z-${index}`}
                                    node={node}
                                    cx={(node.x - focus.x) * k}
                                    cy={(node.y - focus.y) * k}
                                    r={node.r * k}
                                    onClick={onClick}
                                    onDoubleClick={onDoubleClick}
                                    onAction={(action, node) => props.onAction?.(action, node.data)}
                                />
                            ))
                        }
                    </g>
                    <g>
                        {root
                            .descendants()
                            .slice(1)
                            .filter(node => node.data.obj.type === 'team')
                            .map((node, index) => (
                                <TeamNode
                                    key={`t-${index}`}
                                    node={node}
                                    cx={(node.x - focus.x) * k}
                                    cy={(node.y - focus.y) * k}
                                    r={node.r * k}
                                    onClick={onClick}
                                    onDoubleClick={onDoubleClick}
                                    onAction={(action, node) => props.onAction?.(action, node.data)}
                                />
                            ))
                        }
                    </g>
                    <g>
                        {root
                            .descendants()
                            .slice(1)
                            .filter(node => node.data.obj.type === 'role')
                            .map((node, index) => (
                                <RoleNode
                                    key={`r-${index}`}
                                    node={node}
                                    cx={(node.x - focus.x) * k}
                                    cy={(node.y - focus.y) * k}
                                    r={node.r * k}
                                    onDoubleClick={onDoubleClick}
                                    onAction={(action, node) => props.onAction?.(action, node.data)}
                                />
                            ))
                        }
                    </g>
                    <g>
                        {root
                            .descendants()
                            .slice(1)
                            .map((node, index) => (
                                <TextLabel
                                    key={`l-${index}`}
                                    x={(node.x - focus.x) * k - node.r * k}
                                    y={(node.y - focus.y) * k - node.r * k}
                                    r={node.r * k}
                                    themeMode={themeMode}
                                    show={node.parent === focus}
                                    node={node.data}
                                />
                            ))
                        }
                    </g>
                </svg>
            </Dropdown>
            <Legend themeMode={themeMode} node={focus}/>
        </div>
    );
};

export default GraphComponent;
