import React from 'react';
import {Link} from 'react-router-dom';

import {Flex, Table} from 'antd';
import type {ColumnsType} from 'antd/es/table';

import type {Team} from 'types';
import {asDataSource, map} from 'utils';
import {useTeams} from 'hooks/useTeams';
import {useRoles} from 'hooks/useRoles';

function createColumns(teams: Record<string, Team>) {
    const columns: ColumnsType = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, {id}) => <Link to={`/roles/${id}`}>{text}</Link>
        },
        {
            title: 'Team',
            key: 'team',
            render: (_, {team}) => {
                const parentTeam = team?.id ? teams[team.id] : null;

                if (!parentTeam) {
                    return team.id;
                }

                return (
                    <Flex align={'center'} gap={'1rem'}>
                        <Link to={`/zones/${parentTeam.id}`}>{parentTeam.name}</Link>
                    </Flex>
                )
            }
        },
        {
            title: 'Accountabilities',
            dataIndex: 'accountabilities',
            key: 'accountabilities'
        }
    ];
    return columns;
}

const RolesListPage = () => {
    const roles = useRoles();
    const teams = map(useTeams());
    return (
        <Table
            columns={createColumns(teams)}
            dataSource={asDataSource(roles)}
            pagination={false}
        />
    );
}

export default RolesListPage;
