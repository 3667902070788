import {Form, Input} from 'antd';
import {TextField} from 'types';

const TextFormItem = (props: {
    field: TextField,
    value: string|undefined,
    setValue: (value: string|null) => void,
}) => {
    return (
        <Form.Item
            label={props.field.name}
            rules={[{required: props.field.required, message: "Please enter a value."}]}
            name={props.field.name}
            initialValue={props.value}
        >
            <Input onChange={event => {
                props.setValue(event.target.value ? event.target.value : null);
            }} />
        </Form.Item>
    );
}

export default TextFormItem;
