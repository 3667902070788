import {useState} from 'react';
import {Avatar} from 'antd';

import type {BaseObject, ObjectPictures} from 'types';
import {initials} from 'utils';
import AvatarEditModal from './AvatarEditModal';
import AvatarPreviewModal from './AvatarPreviewModal';
import styles from './styles.module.scss';

function sizeToPixels(size?: 'small'|'medium'|'large'|number) {
    if (size === 'large') return 512;
    if (size === 'medium') return 192;
    if (size === 'small' || !size) return 48;
    return size as number;
}

function sizeToImage(obj: ObjectPictures, size: number) {
    if (size > 192) return obj.picture_large;
    if (size > 48) return obj.picture_medium;
    return obj.picture_small;
}

const EditableAvatar = (props: {
    obj: BaseObject & ObjectPictures,
    updateObject: (obj: BaseObject & ObjectPictures) => Promise<boolean>,
    size?: 'small'|'medium'|'large'|number,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [needChange, setNeedChange] = useState(false);
    const size = sizeToPixels(props.size);
    const src = sizeToImage(props.obj, size);
    const text = src ? '' : initials(props.obj.name);

    async function onChange(obj: BaseObject & ObjectPictures) {
        if (await props.updateObject(obj)) {
            setNeedChange(false);
            setShowModal(false);
        }
    }

    async function onRemove() {
        const obj = {...props.obj, picture_large: null, picture_medium: null, picture_small: null};
        return onChange(obj);
    }

    function onClick() {
        setShowModal(true);
    }

    function onCancel() {
        setNeedChange(false);
        setShowModal(false);
    }

    return (
        <>
            <Avatar className={styles.avatar} size={sizeToPixels(props.size)} src={src} onClick={onClick}>{text}</Avatar>
            {(!props.obj.picture_large || needChange) ?
                <AvatarEditModal
                    obj={props.obj}
                    open={showModal}
                    onChange={onChange}
                    onCancel={onCancel}
                /> : null
            }
            {(!!props.obj.picture_large && !needChange) ?
                <AvatarPreviewModal
                    obj={props.obj}
                    open={showModal}
                    onRemove={onRemove}
                    onChange={() => setNeedChange(true)}
                    onCancel={onCancel}
                /> : null
            }
        </>
    );
}

export default EditableAvatar;
