import React, {useState} from 'react';
import {Layout, theme} from 'antd';
import styles from './ForgotPasswordPage.module.scss';
import ForgotPasswordForm from './ForgotPasswordForm';
import {Typography} from 'antd';

import {useThemeMode} from 'hooks/useThemeMode';

import logo from 'assets/logo-light.png';
import logoDark from 'assets/logo-dark.png';

const ForgotPasswordPage = () => {
    const themeMode = useThemeMode();
    const src = themeMode === 'light' ? logoDark : logo;
    const {token: {colorBgContainer}} = theme.useToken();
    const [email, setEmail] = useState('');

    function onFinish(email: string) {
        setEmail(email);
    }

    if (email) {
        return (
            <Layout className={`auth-page ${styles.page} theme-${themeMode}`} style={{background: colorBgContainer}}>
                <div className={`auth-page ${styles.page}`}>
                    <div className="auth-container">
                        <div className="login-logo">
                            <img src={src} alt={'Viggo'}/>
                        </div>
                        <Typography.Title level={2}>Reset Email Sent</Typography.Title>
                        <Typography.Text className="reset-link">Please check <span>{email}</span> for a message
                            containing a reset link.</Typography.Text>
                    </div>
                </div>
            </Layout>
        );
    }

    return (
        <Layout className={`${styles.page} theme-${themeMode}`} style={{background: colorBgContainer}}>
            <div className="auth-container">
                <div className="login-logo">
                    <img src={src} alt={'Viggo'}/>
                </div>
                <ForgotPasswordForm onFinish={onFinish}/>
                <div className="login-help-text">
                    Back to <a href="/login">Login?</a>
                </div>
            </div>
        </Layout>
    );
}

export default ForgotPasswordPage;
