import type {Team} from 'types';

import {useTemplate} from 'hooks/useTemplates';

import Object from 'components/Object';
import MarkdownDiv from 'components/MarkdownDiv';
import DrawerDescription from 'components/drawers/common/DrawerDescription';
import FieldDrawerDescription from 'components/drawers/common/FieldDrawerDescription';


const TeamDrawerDisplay = (props: {
    team: Team
}) => {
    const template = useTemplate(props.team?.template.id, props.team?.type);
    const templateFields = template ? template.fields : [];

    return (
        <>
            <MarkdownDiv markdown={props.team.purpose}/>
            <DrawerDescription title={'Name'}>
                {props.team.name}
            </DrawerDescription>
            {templateFields.map((field, i) => {
                return (
                    <FieldDrawerDescription
                        key={`${field.type}=${field.name}-${i}`}
                        obj={props.team}
                        field={field}
                    />
                );
            })}
            <Object style={{margin: '2em 0'}} object={props.team}/>
        </>
    );
}

export default TeamDrawerDisplay;
