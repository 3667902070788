import React from 'react';
import {Link, useParams} from 'react-router-dom';

import {Card, Descriptions, DescriptionsProps} from 'antd';

import type {Role, Team} from 'types';
import {map} from 'utils';
import Object from 'components/Object';
import MainContent from 'components/layout/MainContent';

import {useRole} from 'hooks/useRoles';
import {useTeams} from 'hooks/useTeams';
import MarkdownDiv from 'components/MarkdownDiv';

function items(role: Role, team: Team) {
    const items: DescriptionsProps['items'] = [
        {
            key: 'name',
            label: 'Name',
            children: role.name,
            span: 4
        },
        {
            key: 'team',
            label: 'Team',
            children: <Link to={`/teams/${team?.id}`}>{team?.name}</Link>,
            span: 4
        }
    ];

    return items;
}


const RoleDetailPage = () => {
    let { roleId } = useParams();

    const teams = map(useTeams());
    const role = useRole(roleId);

    if (!role) {
        return null;
    }

    const team = teams[role.team.id];

    return (
        <MainContent>
            <Card>
                <Descriptions
                    title="Role Info"
                    items={items(role, team)}
                    column={4}
                />
                <MarkdownDiv markdown={role.accountabilities} />
            </Card>
            <Object object={role}/>
        </MainContent>
    );
}

export default RoleDetailPage;
