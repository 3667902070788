import type {
    BaseObject,
    Field,
    MultiSelectField,
    OneToManyField,
    OneToOneField,
    SingleSelectField,
    TextField
} from 'types';
import TextFieldDrawerDescription from './TextFieldDrawerDescription';
import SingleSelectFieldDrawerDescription from './SingleSelectFieldDrawerDescription';
import MultiSelectFieldDrawerDescription from './MultiSelectFieldDrawerDescription';
import OneToOneFieldDrawerDescription from './OneToOneFieldDrawerDescription';
import OneToManyFieldDrawerDescription from './OneToManyFieldDrawerDescription';

const FieldDrawerDescription = (props: {
    obj: BaseObject
    field: Field
}) => {
    if (props.field.type === 'text') {
        return <TextFieldDrawerDescription obj={props.obj} field={props.field as TextField} />
    }
    if (props.field.type === 'single-select') {
        return <SingleSelectFieldDrawerDescription obj={props.obj} field={props.field as SingleSelectField} />
    }
    if (props.field.type === 'multi-select') {
        return <MultiSelectFieldDrawerDescription obj={props.obj} field={props.field as MultiSelectField} />
    }
    if (props.field.type === 'one-to-one') {
        return <OneToOneFieldDrawerDescription obj={props.obj} field={props.field as OneToOneField} />
    }
    if (props.field.type === 'one-to-many') {
        return <OneToManyFieldDrawerDescription obj={props.obj} field={props.field as OneToManyField} />
    }
    return null;
}

export default FieldDrawerDescription;
