import type {Goal} from 'types';

import {useGoal} from 'hooks/useGoals';
import GoalDrawerDisplay from './GoalDrawerDisplay';
import GoalForm from 'components/forms/GoalForm';


const GoalDrawerContents = (props: {
    goal: Goal | undefined,
    edit?: boolean,
    setEdit?: (value: boolean) => void
}) => {
    /* This redundancy forces a re-render on goal data update. */
    const goal = useGoal(props.goal?.id);
    const onFinish = () => props.setEdit?.(false);

    if (!goal) return null;

    if (props.edit) {
        return (<GoalForm goal={goal} onSave={onFinish} onCancel={onFinish} />);
    }

    return <GoalDrawerDisplay goal={goal} />;
}

export default GoalDrawerContents;
