import {Layout} from 'antd';

import SiderLogo from './SiderLogo';
import SiderMenu from './SiderMenu';

import styles from './MainSider.module.scss';

const MainSider = () => {
    return (
        // <Layout.Sider className={styles.sider} width={300} style={{background: colorBgContainer}}>
        <Layout.Sider className={`${styles.sider} gg-sidemenu`} width={290}>
            <SiderLogo/>
            <SiderMenu />
        </Layout.Sider>
    );
}

export default MainSider;
