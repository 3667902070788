import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import type {ServiceAgreement, SliceStatus, Organization, ErrorDetail} from 'types';
import {CLEAR_ORG_ACTION, LOGOUT_ACTION, RootState} from 'store';
import apiClient from 'api';


type ServiceAgreementsState = {
    entities: ServiceAgreement[],
    status: SliceStatus,
    error: string,
};

export const fetchServiceAgreements = createAsyncThunk<ServiceAgreement[]>('serviceAgreements/fetch', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    return await apiClient.get(`/org/${state.org.value.id}/service-agreements`);
});

export const deleteServiceAgreement = createAsyncThunk<ServiceAgreement, string, {
    rejectValue: ErrorDetail,
}>('serviceAgreement/delete', async (serviceAgreementId, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    try {
        return await apiClient.delete<ServiceAgreement>(`/org/${state.org.value.id}/service-agreements/${serviceAgreementId}`);
    } catch (e) {
        return thunkAPI.rejectWithValue((await (e as Response).json()) as ErrorDetail);
    }
});

export const createServiceAgreement = createAsyncThunk<ServiceAgreement, ServiceAgreement, {
    rejectValue: ErrorDetail,
}>('serviceAgreement/create', async (serviceAgreement, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    try {
        return await apiClient.post(`/org/${state.org.value.id}/service-agreements`, serviceAgreement);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

type UpdateServiceAgreement = Partial<ServiceAgreement> & {id: string};
export const updateServiceAgreement = createAsyncThunk<ServiceAgreement, UpdateServiceAgreement, {
    rejectValue: ErrorDetail,
}>('serviceAgreement/update', async (data, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    try {
        return await apiClient.patch(`/org/${state.org.value.id}/service-agreements/${data.id}`, data);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

const INITIAL_STATE = {
    entities: [],
    status: 'idle' as ServiceAgreementsState['status'],
    error: ''
};

// noinspection JSUnusedGlobalSymbols
const serviceAgreementSlice = createSlice({
    name: 'serviceAgreements',
    initialState: INITIAL_STATE satisfies ServiceAgreementsState as ServiceAgreementsState,
    reducers: {
        clearError: (state) => {
            state.error = '';
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchServiceAgreements.pending, (state: ServiceAgreementsState) => {
                state.status = 'pending';
            })
            .addCase(fetchServiceAgreements.fulfilled, (state: ServiceAgreementsState, action: PayloadAction<ServiceAgreement[]>) => {
                state.status = 'succeeded';
                state.entities = action.payload as ServiceAgreement[];
            })
            .addCase(fetchServiceAgreements.rejected, (state: ServiceAgreementsState, action) => {
                state.status = 'failed';
                //state.error = action.error.message;
                console.log(action);
            })
            .addCase(createServiceAgreement.fulfilled, (state: ServiceAgreementsState, action: PayloadAction<ServiceAgreement|ErrorDetail>) => {
                state.entities.push(action.payload as ServiceAgreement);
            })
            .addCase(deleteServiceAgreement.fulfilled, (state: ServiceAgreementsState, action) => {
                state.entities = state.entities.filter((serviceAgreement: ServiceAgreement) => serviceAgreement.id !== action.payload.id);
            })
            .addCase(CLEAR_ORG_ACTION, (state: ServiceAgreementsState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(LOGOUT_ACTION, (state: ServiceAgreementsState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(updateServiceAgreement.fulfilled, (state: ServiceAgreementsState, action: PayloadAction<ServiceAgreement|ErrorDetail>) => {
                const serviceAgreement = action.payload as ServiceAgreement;
                const index = state.entities.findIndex(r => r.id === serviceAgreement.id);
                state.entities[index] = serviceAgreement;
            })
    }
});

export const selectServiceAgreements = (state: RootState) => state.serviceAgreements.entities;
export const selectServiceAgreementStatus = (state: RootState) => state.serviceAgreements.status;

export default serviceAgreementSlice.reducer;
