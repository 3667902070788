import React from 'react';
import {Form, FormInstance, Input} from 'antd';

export const EditableContext = React.createContext<FormInstance<any> | null>(null);

function EditableCell<T>(props: React.PropsWithChildren<{
    editing: boolean,
    dataIndex: keyof T;
    title: React.ReactNode;
    record: T;
    index: number
}>) {
    return (
        <td>
            {props.editing ? (
                <Form.Item
                    name={props.dataIndex.toString()}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${props.title}!`,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            ) : (
                props.children
            )}
        </td>
    );
}

export default EditableCell;
