import React, {CSSProperties, useState} from 'react';
import {Button, message} from 'antd';

import CreateTeamSteps from 'components/steps/teams/CreateTeamSteps';
import CreateModal from 'components/steps/_common/CreateModal';

const CreateTeamButton = (props: {
    style?: CSSProperties
}) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [openModal, setOpenModal] = useState(false);

    async function onSuccess() {
        setOpenModal(false);
        return messageApi.success('Your team was created successfully.');
    }

    function onClose() {
        setOpenModal(false);
    }

    return (
        <>
            <Button
                style={props.style}
                onClick={() => setOpenModal(true)}
            >
                Create a team
            </Button>
            <CreateModal
                title={'Create a new team'}
                open={openModal}
                onCancel={onClose}
            >
                <CreateTeamSteps onSuccess={onSuccess} onCancel={onClose} />
            </CreateModal>
            {contextHolder}
        </>
    );
}

export default CreateTeamButton;
