import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {App, Form, Input, Button, Select, Flex, Row, Col} from 'antd';

import type {ErrorDetail, ErrorDetailDispatch, Template, Field} from 'types';
import {AppDispatch, useAppDispatch} from 'store';
import {updateTemplate} from 'features/templateSlice';
import {useTemplates} from 'hooks/useTemplates';

import styles from './styles.module.scss';
import FieldsTable from './FieldsTable';
import {map} from 'utils';


type FormFieldsType = {
    name?: string,
    description?: string,
    objectType?: Template['objectType']
};

function defaultFields(fields: Field[] | undefined) {
    if (fields) {
        return fields.map(field => ({...field}));
    }
    return []
}

const EditTemplatePage = () => {
    let { templateId } = useParams();
    const templates = map(useTemplates());
    const template = templateId ? templates[templateId] : undefined;

    const navigate = useNavigate();
    const {message} = App.useApp();

    const dispatch: AppDispatch = useAppDispatch();
    const [fields, setFields] = useState<Field[]>();

    useEffect(() => {
        if (!fields && template) {
            setFields(defaultFields(template.fields));
        }
    }, [fields, template]);

    if (!template || !fields) {
        return null;
    }

    async function onFinish(values: FormFieldsType) {
        const newTemplate = {...values, fields: fields as Field[], id: templateId} as Template;

        const res = await dispatch(updateTemplate(newTemplate));
        if ((res as ErrorDetailDispatch<Template>).error) {
            return message.error((res.payload as ErrorDetail).detail.toString());
        }
        navigate(`/settings/templates/${templateId}`);
    }

    return (
        <div className={styles.page}>
            <p>A template allows you to define the information collected for objects in this organization.</p>
            <Form
                name="template"
                initialValues={{
                    name: template.name,
                    objectType: template.objectType,
                    description: template.description,
                }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item<FormFieldsType>
                            label="Name"
                            name="name"
                            className='formControl'
                            rules={[{required: true, message: 'Please input a name!'}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item<FormFieldsType>
                            label="Object"
                            name="objectType"
                            className='formControl'
                            rules={[{required: true, message: 'Select a template type.'}]}
                        >
                            <Select
                                options={[
                                    {value: 'decision', label: 'Decision'},
                                    {value: 'goal', label: 'Goal'},
                                    {value: 'role', label: 'Role'},
                                    {value: 'team', label: 'Team'},
                                    {value: 'zone', label: 'Zone'},
                                ]}
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item<FormFieldsType>
                            label="Description"
                            name="description"
                            className='formControl'
                            help={'Markdown is allowed.'}
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>

                </Row>

                    <div className={styles.dragCol}>
                        <h4>Fields</h4>
                        <FieldsTable fields={fields} setFields={setFields}/>
                    </div>


                <Flex>
                    <Form.Item className={styles.btnBar}>
                        <Button type="default" style={{marginRight: '8px'}} onClick={() => navigate('/settings')}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
                </Flex>
            </Form>
        </div>
    );
}

export default EditTemplatePage;
