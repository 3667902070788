import {useNavigate} from 'react-router-dom';
import {Button, Flex, message, Modal, theme} from 'antd';
import {CloseCircleOutlined} from '@ant-design/icons';

import type {Goal, PlatformDispatch, PlatformError} from 'types';
import {personIsAdmin} from 'utils';
import {useCurrentUser} from 'hooks/useCurrentUser';

import {AppDispatch, useAppDispatch} from 'store';
import {deleteGoal} from 'features/goalSlice';

const DeleteGoalButton = (props: {
    goalId: string
}) => {
    const dispatch: AppDispatch = useAppDispatch();

    const isAdmin = personIsAdmin(useCurrentUser());
    const {token: {colorError}} = theme.useToken();
    const navigate = useNavigate();

    if (!isAdmin) {
        return null;
    }

    async function onConfirm() {
        const res = await dispatch(deleteGoal(props.goalId) as any) as PlatformDispatch<Goal>;
        if (res.error) {
            return message.error((res.payload as PlatformError).message);
        }
        message.success('Your goal was successfully deleted.');
        navigate('/goals', {replace:true});
    }

    return (
        <Flex>
            <Button
                danger
                onClick={() => {
                    Modal.confirm({
                        title: 'Confirm Goal Deletion',
                        content: 'Are you sure you want to permanently delete this goal?  This operation cannot be undone.',
                        footer: (_, {OkBtn, CancelBtn}) => (
                            <>
                                <CancelBtn/>
                                <OkBtn />
                            </>
                        ),
                        okText: 'Delete Goal',
                        okButtonProps: {type:'default', danger:true},
                        icon: <CloseCircleOutlined style={{color:colorError}}/>,
                        onOk: onConfirm
                    });
                }}
            >
                Delete Goal
            </Button>
        </Flex>
    )
}

export default DeleteGoalButton;
