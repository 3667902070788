import {Team} from 'types';
import CreateDecisionSteps from 'components/steps/decisions/CreateDecisionSteps';
import CreateButton from 'components/steps/_common/CreateButton';

const AddDecisionButton = (props: {
    team: Team
}) => {
    return (
        <CreateButton
            title={'Add Decision'}
        >
            {(onSuccess, onCancel) => (
                <CreateDecisionSteps team={props.team} onSuccess={onSuccess} onCancel={onCancel} />
            )}
        </CreateButton>
    );
}

export default AddDecisionButton;
