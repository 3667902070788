import * as d3 from 'd3';
import {CSSProperties} from 'react';
import {Typography} from 'antd';

import {titleCase} from 'utils';
import type { GraphNode } from './types';

const Legend = (props: {
    node: d3.HierarchyCircularNode<GraphNode>
    themeMode: 'light'|'dark'
}) => {
    const style = {
        color: props.themeMode === 'dark' ? 'white' : 'black',
        position: 'absolute', top: '12px', left: '16px',
        backgroundColor: props.themeMode === 'dark' ? 'transparent' : 'white',
        padding: '2px',
    } as CSSProperties;

    return (
        <div style={style}>
            <Typography.Title level={2} style={{margin:0, fontSize:'1rem'}}>
                {titleCase(props.node.data.obj.type)}: {props.node.data.obj.name} [{props.node.data.obj.id}]
            </Typography.Title>
        </div>
    );
}

export default Legend;
