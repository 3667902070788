import {useState} from 'react';
import {App} from 'antd';

import {ErrorDetail, ErrorDetailDispatch, Zone} from 'types';

import {AppDispatch, useAppDispatch} from 'store';

import {createZone} from 'features/zoneSlice';

import CreateSteps from 'components/steps/_common/CreateSteps';
import NameStep from 'components/steps/_common/NameStep';
import ParentZoneStep from 'components/steps/_common/ParentZoneStep';
import TemplateStep from 'components/steps/_common/TemplateStep';
import templateSteps from 'components/steps/_common/templateSteps';


import {useTemplates} from 'hooks/useTemplates';
import {map} from 'utils';
import BoundaryStep from './BoundaryStep';


const CreateZoneSteps = (props: {
    zone?: Zone,
    onSuccess: (team: Zone) => void,
    onCancel: () => void,
}) => {
    const {message} = App.useApp();
    const dispatch: AppDispatch = useAppDispatch();

    const [zone, setZone] = useState<Zone>({} as Zone);
    const templates = map(useTemplates('zone'));
    const template = zone.template?.id ? templates[zone.template.id] : undefined;

    function onCancel() {
        props.onCancel();
        setTimeout(() => {
            setZone({} as Zone);
        }, 0);
    }

    async function onCreate() {
        const newZone = {...zone} as Zone;
        if (props.zone) {
            newZone.parentZone = {id: props.zone.id, type: 'zone'};
        }

        const res = await dispatch(createZone(newZone));
        if ((res as ErrorDetailDispatch<Zone>).error) {
            return message.error((res.payload as ErrorDetail).detail);
        }
        props.onSuccess(res.payload as Zone);
    }

    const steps = [
        {
            title: 'Template',
            content:
                <TemplateStep
                    objectType={'zone'}
                    templateId={zone?.template?.id || ''}
                    setTemplate={templateId => {
                        setZone({...zone, template: {id:templateId, type:'template'}});
                    }}
                />,
            valid: () => !!zone.template?.id,
        },
        {
            title: 'Name',
            content:
                <NameStep
                    title="What should this zone be called?"
                    name={zone?.name || ''}
                    setName={name => {
                        setZone({...zone, name});
                    }}
                />,
            valid: () => !!zone.name,
        },
        {
            title: 'Boundary',
            content:
                <BoundaryStep
                    boundary={zone?.boundary || ''}
                    setBoundary={boundary => {
                        setZone({...zone, boundary});
                    }}
                />,
            valid: () => !!zone.boundary,
        }
    ];

    if (!props.zone) {
        steps.push({
            title: 'Parent Zone',
            content:
                <ParentZoneStep
                    title={'Does this zone have a parent?'}
                    parentZone={zone.parentZone?.id || ''}
                    setParentZone={(parentId: string) => {
                        setZone({
                            ...zone,
                            parentZone: {id: parentId, type: 'zone'}
                        });
                    }}
                />,
            valid: () => true
        });
    }

    return (
        <>
            <CreateSteps
                steps={templateSteps(zone, setZone, template, steps)}
                onCreate={onCreate}
                onCancel={onCancel}
            />
        </>
    )
}

export default CreateZoneSteps;
