import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import type {Role, SliceStatus, Organization, ErrorDetail} from 'types';
import {CLEAR_ORG_ACTION, LOGOUT_ACTION, RootState} from 'store';
import apiClient from 'api';


type RolesState = {
    entities: Role[],
    status: SliceStatus,
    error: string,
};

export const fetchRoles = createAsyncThunk<Role[]>('roles/fetch', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    return await apiClient.get(`/org/${state.org.value.id}/roles`);
});

export const deleteRole = createAsyncThunk<Role, string, {
    rejectValue: ErrorDetail,
}>('role/delete', async (userId, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    try {
        return await apiClient.delete<Role>(`/org/${state.org.value.id}/roles/${userId}`);
    } catch (e) {
        return thunkAPI.rejectWithValue((await (e as Response).json()) as ErrorDetail);
    }
});

export const createRole = createAsyncThunk<Role, Role, {
    rejectValue: ErrorDetail,
}>('role/create', async (role, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    try {
        return await apiClient.post(`/org/${state.org.value.id}/roles`, role);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

type UpdateRole = Partial<Role> & {id: string};
export const updateRole = createAsyncThunk<Role, UpdateRole, {
    rejectValue: ErrorDetail,
}>('role/update', async (data, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    try {
        return await apiClient.patch(`/org/${state.org.value.id}/roles/${data.id}`, data);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

const INITIAL_STATE = {
    entities: [],
    status: 'idle' as RolesState['status'],
    error: ''
};

// noinspection JSUnusedGlobalSymbols
const roleSlice = createSlice({
    name: 'roles',
    initialState: INITIAL_STATE satisfies RolesState as RolesState,
    reducers: {
        clearError: (state) => {
            state.error = '';
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchRoles.pending, (state: RolesState) => {
                state.status = 'pending';
            })
            .addCase(fetchRoles.fulfilled, (state: RolesState, action: PayloadAction<Role[]>) => {
                state.status = 'succeeded';
                state.entities = action.payload as Role[];
            })
            .addCase(fetchRoles.rejected, (state: RolesState, action) => {
                state.status = 'failed';
                //state.error = action.error.message;
                console.log(action);
            })
            .addCase(createRole.fulfilled, (state: RolesState, action: PayloadAction<Role|ErrorDetail>) => {
                state.entities.push(action.payload as Role);
            })
            .addCase(deleteRole.fulfilled, (state: RolesState, action) => {
                state.entities = state.entities.filter((user: Role) => user.id !== action.payload.id);
            })
            .addCase(CLEAR_ORG_ACTION, (state: RolesState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(LOGOUT_ACTION, (state: RolesState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(updateRole.fulfilled, (state: RolesState, action: PayloadAction<Role|ErrorDetail>) => {
                const role = action.payload as Role;
                const index = state.entities.findIndex(r => r.id === role.id);
                Object.assign(state.entities[index], role);
                state.entities = [...state.entities];
            })
    }
});

export const selectRoles = (state: RootState) => state.roles.entities;
export const selectRoleStatus = (state: RootState) => state.roles.status;

export default roleSlice.reducer;
