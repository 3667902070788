import React, {useState} from 'react';
import {Dropdown, Popover} from 'antd';

import AnimatedCircle from './AnimatedCircle';
import AnimatedImage from './AnimatedImage';
import NodeActions from './NodeActions';
import {NodeProps} from './types';

const RoleNode = (props: NodeProps) => {
    const [hover, setHover] = useState(false);

    return (
        <>
            <Popover open={hover} content={props.node.data.obj.name}>
                <AnimatedImage
                    href={props.node.data.user?.picture_medium}
                    x={props.cx - props.r}
                    y={props.cy - props.r}
                    height={props.r * 2}
                    width={props.r * 2}
                    onClick={event => NodeActions.onClick(event, props)}
                    onDoubleClick={event => NodeActions.onDoubleClick(event, props)}
                    onContextMenu={event => event.stopPropagation()}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                />
            </Popover>
            <Dropdown
                menu={{
                    items: NodeActions.roleMenuItems,
                    onClick: menu => NodeActions.handleMenuClick(menu, props)
                }}
                trigger={['contextMenu']}
            >
                <AnimatedCircle
                    cx={props.cx}
                    cy={props.cy}
                    r={props.r}

                    fill={'#0011c8'}
                    fillOpacity={props.node.data.user ? 1 : .05}
                    stroke={'#0011c8'}
                    strokeWidth={hover ? 2 : undefined}
                    onClick={event => NodeActions.onClick(event, props)}
                    onDoubleClick={event => NodeActions.onDoubleClick(event, props)}
                    onContextMenu={event => event.stopPropagation()}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    style={{display: props.r < 8 || !props.node.data.user || !props.node.data.user?.picture_medium ? 'inline' : 'none'}}
                />
            </Dropdown>
        </>
    );
}

export default RoleNode;
