import {Field} from 'types';
import {Tag} from 'antd';

const FieldOptionDisplay = (props: {
    field: Field
}) => {
    if (props.field.type === 'single-select' || props.field.type === 'multi-select') {
        return (
            <>
                {props.field.options?.map(option =>  (
                    <Tag className='field-value' key={option}>{option}</Tag>
                ))}
            </>
        )
    }

    if (props.field.type === 'one-to-one' || props.field.type === 'one-to-many') {
        return (
            <>
                {props.field.allowed?.map(value =>  (
                    <Tag className='field-value'>{value}</Tag>
                ))}
            </>
        )
    }

    return null;
}

export default FieldOptionDisplay;
