import React from 'react';
import {Link} from 'react-router-dom';

import {Table} from 'antd';
import type {ColumnsType} from 'antd/es/table';

import {useServiceAgreements} from 'hooks/useServiceAgreements';
import {asDataSource} from 'utils';


const columns: ColumnsType = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, {id}) => {
            return (<Link to={`/service-agreements/${id}`}>{text}</Link>);
        }
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    /*
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: text => (
            <Tag color={statusColor(text)} key={'status'}>
                {text.toUpperCase()}
            </Tag>
        )
    }
     */
];

const ServiceAgreementsListPage = () => {
    const serviceAgreements = useServiceAgreements();
    return (
        <Table
            columns={columns}
            dataSource={asDataSource(serviceAgreements)}
            pagination={false}
        />
    );
}

export default ServiceAgreementsListPage;
