import {useEffect, useState} from 'react';

import {TeamGraphLink, TeamGraphNode} from './types';
import {useThemeMode} from 'hooks/useThemeMode';

import drawNetwork from './drawNetwork';
import NetworkGraphNode from './NetworkGraphNode';
import NetworkGraphLink from './NetworkGraphLink';
import {BaseObject} from '../../../types';

const RADIUS = 10;

function linkKey(link: TeamGraphLink) {
    const source = link.source as TeamGraphNode;
    const target = link.target as TeamGraphNode;
    return `${source.obj.id}-${target.obj.id}`;
}

export function nodeMap(nodes: TeamGraphNode[]) {
    const map = {} as Record<string, TeamGraphNode>;
    for (const node of nodes) {
        map[node.obj.id] = node;
    }
    return map;
}

const NetworkGraph = (props: {
    width: number,
    height: number,
    data: {nodes: TeamGraphNode[], links: TeamGraphLink[]}
    onClick?: (obj: BaseObject) => void
}) => {
    const themeMode = useThemeMode();
    const [nodes, setNodes] = useState<Record<string, TeamGraphNode>>();
    const [links, setLinks] = useState<TeamGraphLink[]>();

    useEffect(() => {
        const {nodes, links} = drawNetwork(props);
        setNodes(nodeMap(nodes));
        setLinks(links);
    }, [props]);

    if (!nodes || !links) {
        return null;
    }

    // FIXME: switch to canvas?
    // https://www.react-graph-gallery.com/network-chart
    return (
        <div>
            <svg
                viewBox={`0 0 ${props.width} ${props.height}`}
                width={props.width}
                height={props.height}
            >
                <g>
                    {links.map(link => (
                        <NetworkGraphLink
                            themeMode={themeMode}
                            key={linkKey(link)}
                            source={nodes[(link.source as TeamGraphNode).obj.id]}
                            target={nodes[(link.target as TeamGraphNode).obj.id]}
                        />
                    ))}
                </g>
                <g>
                    {Object.keys(nodes).map(key => (
                        <NetworkGraphNode
                            themeMode={themeMode}
                            key={key}
                            node={nodes[key]}
                            r={RADIUS}
                            onClick={props.onClick}
                        />
                    ))}
                </g>
            </svg>
        </div>
    );
}

export default NetworkGraph;
