// noinspection JSUnusedGlobalSymbols
import {redirect} from 'react-router-dom';

import type {ErrorDetail} from 'types';

export const API_URL = process.env.REACT_APP_API_URL || 'https://api.viggo.team';

export class Client {
    private token: string|undefined;

    constructor(token?: string) {
        this.token = token;
    }

    setToken(token: string|undefined) {
        this.token = token;
    }

    async request<T>(method: string, url: string, data?: any, options?: {token?: string}): Promise<T> {
        url = url.startsWith('/') ? `${API_URL}${url}` : url;
        method = method.toUpperCase();
        const token = this.token ? this.token : options?.token;

        const headers = {} as Record<string, string>;
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        if (!['GET', 'DELETE'].includes(method)) {
            headers['Content-Type'] = 'application/json';
        }

        const res = await fetch(
            url,
            {
                method, headers,
                body: data ? JSON.stringify(data) : undefined,
            }
        )

        if (res.status >= 300) {
            const detail = await res.json() as ErrorDetail;
            detail.status = res.status;
            throw detail;
        }
        return await res.json() as T;
    }

    async get<T>(url: string) {
        return this.request<T>('GET', url, undefined);
    }

    async patch<T>(url: string, data: any) {
        return this.request<T>('PATCH', url, data);
    }

    async post<T>(url: string, data: any) {
        return this.request<T>('POST', url, data);
    }

    async delete<T>(url: string) {
        return this.request<T>('DELETE', url, undefined);
    }
}

export class StorageClient extends Client {
    private readonly key;

    constructor(key: string = 'access_token') {
        const token = window.sessionStorage.getItem(key) || undefined;
        super(token);
        this.key = key;
    }

    setToken(token: string|undefined) {
        if (token) {
            window.sessionStorage.setItem(this.key, token);
        } else {
            window.sessionStorage.removeItem(this.key);
        }
        super.setToken(token);
    }
}

class ApiClient extends StorageClient {
    constructor() {
        super(process.env.REACT_APP_ACCOUNT_ID!);
    }

    async get<T>(url: string) {
        try {
            return await super.get<T>(url)
        } catch (e) {
            const detail = e as ErrorDetail;
            if (detail.status === 401) {
                throw redirect('/login');
            }
            throw(e);
        }
    }
}

const apiClient = new ApiClient();
export default apiClient;
