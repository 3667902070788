import {BaseObject, TextField} from 'types';
import DrawerDescription from './DrawerDescription';

const TemplateFieldDrawerDescription = (props: {
    obj: BaseObject & {[p:string]: any}
    field: TextField
}) => {
    const value = props.obj[props.field.name];
    if (!value) {
        return null;
    }

    return (
        <DrawerDescription title={props.field.name}>
            {value}
        </DrawerDescription>
    );
}

export default TemplateFieldDrawerDescription;
