import React, {useState} from 'react';

import type {DragEndEvent} from '@dnd-kit/core';
import {DndContext, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from '@dnd-kit/sortable';

import {Table, Button, Form, Typography, TableProps} from 'antd';
import type {Keyed} from 'types';

import EditableCell from './EditableCell';
import {CSS} from '@dnd-kit/utilities';

type DataType = Keyed<{ value: string }>;

function toDataSource(options: string[]) {
    return options.map((option, index) => {
        return {
            value: option,
            key: index.toString()
        }
    });
}

function fromDataSource(values: DataType[]) {
    return values.map(value => value.value);
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
}

const Row: React.FC<Readonly<RowProps>> = (props) => {
    const {attributes, listeners, setNodeRef, transform, isDragging} = useSortable({
        id: props['data-row-key'],
    });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        // transition,
        cursor: 'move',
        ...(isDragging ? {position: 'relative', zIndex: 9999} : {}),
    };

    return (
        <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />
    );
};

const OptionsTable = (props: {
    options?: string[],
    setOptions: (options: string[]) => void
}) => {
    const [form] = Form.useForm();
    const {options = []} = props;
    const dataSource = toDataSource(options);
    const [editingKey, setEditingKey] = useState('');

    const isEditing = (record: DataType) => record.key === editingKey;

    const handleDelete = (key: React.Key) => {
        const newData = dataSource.filter((item) => item.key !== key);
        props.setOptions(fromDataSource(newData));
    };

    const columns = [
        {
            title: 'Value',
            dataIndex: 'value',
            editable: true
        },
        {
            title: 'actions',
            dataIndex: 'actions',
            render: (_: any, record: DataType) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link onClick={() => save(record.key)} style={{marginInlineEnd: 8}}>
                            Save
                        </Typography.Link>
                        <Typography.Link onClick={cancel}>
                            Cancel
                        </Typography.Link>
                    </span>
                ) : (
                    <span>
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)} style={{marginInlineEnd: 8}}>
                            Edit
                        </Typography.Link>
                        <Typography.Link type={'danger'} disabled={editingKey !== ''}  onClick={() => handleDelete(record.key)}>
                            Delete
                        </Typography.Link>
                    </span>
                );
            },
        },
    ];

    const mergedColumns: TableProps<DataType>['columns'] = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: DataType) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const save = async (key: React.Key) => {
        try {
            const row = (await form.validateFields()) as DataType;

            const newData = [...dataSource];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
            } else {
                newData.push(row);
            }
            props.setOptions(fromDataSource(newData));
            setEditingKey('');
        } catch (e) {
            console.log('Validation failed: ' + e)
        }
    };

    const edit = (record: Partial<DataType> & { key: React.Key }) => {
        form.setFieldsValue({...record});
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
                distance: 1,
            },
        }),
    );

    const onDragEnd = ({active, over}: DragEndEvent) => {
        if (active.id !== over?.id) {
            const activeIndex = dataSource.findIndex((i: DataType) => i.key === active.id);
            const overIndex = dataSource.findIndex((i: DataType) => i.key === over?.id);
            props.setOptions(fromDataSource(arrayMove(dataSource, activeIndex, overIndex)));
        }
    };

    const addOption = () => {
        props.setOptions([...options, `Option ${options.length + 1}`])
    }

    return (
        <>
            <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                <SortableContext
                    // rowKey array
                    items={dataSource.map((i) => i.key)}
                    strategy={verticalListSortingStrategy}
                >
                    <Form form={form} component={false}>
                        <Table<DataType>
                            className={'empty-none'}
                            components={{
                                body: {cell: EditableCell, row: Row},
                            }}
                            rowKey="key"
                            columns={mergedColumns}
                            dataSource={dataSource}
                            pagination={false}
                            showHeader={false}
                        />
                    </Form>
                </SortableContext>
            </DndContext>
            <Button type={'link'} onClick={addOption}>Add Option</Button>
        </>
    );
};

export default OptionsTable;
