import {Form, Input} from 'antd';

const PurposeStep = (props: {
    purpose: string,
    setPurpose: (purpose: string) => void,
}) => {
    const [form] = Form.useForm<{ purpose: string }>();
    Form.useWatch(values => {
        if (values.purpose !== undefined) {
            props.setPurpose(values.purpose);
        }
    }, form);

    return (
        <Form
            name="purposeStep"
            form={form}
            style={{maxWidth: 600}}
            initialValues={{purpose: props.purpose}}
            layout="vertical"
            autoComplete="off"
            className='formControl'
        >
            <Form.Item<{purpose: string}>
                label="What is the purpose of this team?"
                name="purpose"
                help={'Enter a short-form purpose e.g. 144 characters.'}

                rules={[{
                    required: true,
                    message: "Enter a short-form purpose e.g. 144 characters."
                }]}
            >
                <Input.TextArea rows={4} />
            </Form.Item>
        </Form>)
}

export default PurposeStep;
